import axios from "axios";
import {
    CLIENT_CREATE_URL,
    CLIENT_DOCUMENTS_URL,
    CLIENT_INFO_URL,
    CLIENT_INVOICES_URL,
    CLIENT_PAYMENTS_URL,
    CLIENT_PROPOSALS_URL,
    CLIENT_TRANSACTIONS_URL,
    PAYNOW_STATUS_URL, PROPOSAL_URL, PROPOSAL_VERIFY_URL,
    SAP_ACCESS_URL
} from "../_config/config";
import {errorToast} from "../_utils/Toasts";

export function sapAccess(hash) {
    return axios.get(SAP_ACCESS_URL + hash)
        .catch(err => {
            if (err.response.status === 400) {
                errorToast('Link wygasł. Przekierowanie na stronę logowania.')
            } else {
                errorToast('Coś poszło nie tak.')
            }
            return err
        })
}

export function getClientData(code) {
    return axios.get(CLIENT_INFO_URL + code)
        .catch(err => {
            if (err.response.status !== 401 && err.response.status !== 503) {
                errorToast('Wystąpił błąd podczas ładowania danych. Spróbuj ponownie później.')
            }
        })
}

export function getClientInvoices(page, pageSize, filters = {}) {
    return axios.get(CLIENT_INVOICES_URL, {params: {page: page, length: pageSize, ...filters}})
        .catch(err => {
            if (err.response.status !== 401 && err.response.status !== 503) {
                errorToast('Wystąpił błąd podczas ładowania danych. Spróbuj ponownie później.')
            }
        })
}

export function getClientPayments(page, pageSize) {
    return axios.get(CLIENT_PAYMENTS_URL, {params: {page: page, length: pageSize}})
        .catch(err => {
            if (err.response.status !== 401) {
                errorToast('Wystąpił błąd podczas ładowania danych. Spróbuj ponownie później.')
            }
        })
}

export function getDocuments(document_type) {
    return axios.get(CLIENT_DOCUMENTS_URL, {params: {document_type: document_type}})
        .catch(err => {
            if (err.response.status !== 401 && err.response.status !== 503) {
                errorToast('Coś poszło nie tak')
            }
        })
}

export function statusPayment(paymentId) {
    return axios.get(PAYNOW_STATUS_URL, {params: {payment_id: paymentId}})
        .catch(err => {
            if (err.response.status !== 401 && err.response.status !== 503) {
                errorToast('Coś poszło nie tak')
            }
        })
}

export function getClientProposals(page, pageSize) {
    return axios.get(CLIENT_PROPOSALS_URL, {params: {page: page, length: pageSize}})
        .catch(err => {
            if (err.response.status !== 401 && err.response.status !== 503) {
                errorToast('Coś poszło nie tak')
            }
        })
}

export function getClientProposal(hash) {
    return axios.get(CLIENT_PROPOSALS_URL + hash + '/')
        .catch(err => {
            if (err.response.status !== 401 && err.response.status !== 503) {
                errorToast('Coś poszło nie tak')
            }
        })
}

export function getClientTransactions(page, pageSize) {
    return axios.get(CLIENT_TRANSACTIONS_URL, {params: {page: page, length: pageSize}})
        .catch(err => {
            if (err.response.status !== 401 && err.response.status !== 503) {
                errorToast('Coś poszło nie tak')
            }
        })
}

export function createClient(values) {
    const data = mapClientData(values);
    return axios.post(CLIENT_CREATE_URL, data)
        .catch(err => err)
}

export function createProposal(tpe, values) {
    return axios.post(PROPOSAL_URL, {tpe: tpe, json: values})
}

export function createProposalSign(proposalHash) {
    return axios.post(CLIENT_PROPOSALS_URL + proposalHash + '/sign_request/')
}

export function signProposal(values) {
    return axios.post(PROPOSAL_VERIFY_URL, values)
}

export function rejectProposal(proposalHash) {
    return axios.post(CLIENT_PROPOSALS_URL + proposalHash + '/cancel/')
}

function mapClientData(values) {
    let payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        phone: values.phone,
        email: values.email,
        name: values.name,
        details: {
            type: values.type,
            customer_1: {
                first_name: values.first_name,
                last_name: values.last_name,
                nip: values.nip,
                pesel: values.pesel,
                phone: values.phone,
                email: values.email,
                name: values.name,
                address: {
                    build_number: values.base_address.build_number,
                    home_number: values.base_address.home_number,
                    postal_code: values.base_address.postal_code,
                    has_home_number: values.base_address.has_home_number,
                    teryt: values.base_address.teryt
                },
            },
            house_point: values.house_point,
            entry_id: values.entry_id
        }
    }

    if (values.is_correspondence_different) {
        payload.details.correspondence = {
            build_number: values.correspondence.build_number,
            home_number: values.correspondence.home_number,
            postal_code: values.correspondence.postal_code,
            has_home_number: values.correspondence.has_home_number,
            teryt: values.correspondence.teryt
        }
    } else {
        values.correspondence = values.base_address;
        payload.details.correspondence = values.base_address;
    }


    if (values.base_address.teryt.street.sym) {
        payload.details.customer_1.address = {
            ...payload.details.customer_1.address,
            sym: values.base_address.teryt.street.sym,
            sym_ul: values.base_address.teryt.street.sym_ul
        };
    } else {
        payload.details.customer_1.address = {
            ...payload.details.customer_1.address,
            sym: values.base_address.teryt.city.sym
        };
    }

    if (values.correspondence.teryt.street.sym) {
        payload.details.correspondence = {
            ...payload.details.correspondence,
            sym: values.correspondence.teryt.street.sym,
            sym_ul: values.correspondence.teryt.street.sym_ul
        };
    } else {
        payload.details.correspondence = {
            ...payload.details.correspondence,
            sym: values.correspondence.teryt.city.sym
        };
    }

    return payload;
}
