import React from "react";
import LabelledText from "../../_common/LabelledText";
import SignProposal from "../../SignProposal";

export default function SignChangeEmail(props) {
    return (
        <div className="row">
            <div className="col-md-12">
                <LabelledText text={props.proposal.json.email} label={'Nowy adres e-mail'}/>
            </div>
            <div className="col-md-12">
                <SignProposal header={'Zmień adres e-mail'}
                              buttonConfirmText={'Zapisz'}
                              buttonRejectText={'Anuluj'}
                              proposal={props.proposal}
                              phone={props.proposal.json.phone}/>
            </div>
        </div>
    )
}