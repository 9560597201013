import React, {Component} from 'react';
import {connect} from "react-redux";
import * as client from "../../store/ducks/client.duck";
import DataTable from "../_common/DataTable";
import ClientTransactions from "./ClientTransactions";

class ClientPayments extends Component {
    componentDidMount() {
        const { page, pageSize } = this.props;
        this.fetchData(page, pageSize);
    }

    fetchData(page, pageSize) {
        this.props.getPayments(page, pageSize);
    }

    onChangePage = (page, pageSize) => {
        if (page !== this.props.page) {
            this.fetchData(page, pageSize);
        }
    }

    typeFormatter = (row) => {
        if (row.wplyw === true) {
            return <span className="label label-success"><b>{row.typ}</b></span>
        }
        return <span className="label label-danger"><b>{row.typ}</b></span>
    }

    wplataFormatter = (row) => {
        return row.wartosc
    }

    splataFormatter = (row) => {
        return row.splata
    }

    columns = [
        {name: 'Typ', dataField: 'typ', formatter: this.typeFormatter},
        {name: 'Data', dataField: 'data'},
        {name: 'Kwota', dataField: 'wartosc', formatter: this.wplataFormatter},
        // {name: 'Wykorzystana', dataField: 'splata', formatter: this.splataFormatter},
        // {name: 'Wolne środki', dataField: 'wolne_srodki'},
        {name: 'Tytułem', dataField: 'tytulem'},
    ]

    render() {
        const { payments, loading, page, pageSize } = this.props;

        return (
            <>
                <div className="row">
                    <div className="col-lg-12">
                        <p>Zaksięgowane wpłaty</p>
                        <DataTable columns={this.columns}
                                   rows={payments.data}
                                   recordsTotal={payments.recordsTotal}
                                   page={page}
                                   pageSize={pageSize}
                                   loading={loading}
                                   onChangePage={this.onChangePage}/>
                    </div>
                </div>
                <div className="row" style={{marginTop: '15px'}}>
                    <div className="col-lg-12">
                        <p>Transakcje</p>
                        <ClientTransactions/>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        payments: state.clientInfo.payments.data,
        loading: state.clientInfo.payments.loading,
        page: state.clientInfo.payments.page,
        pageSize: state.clientInfo.payments.pageSize
    }
};

export default connect(
    mapStateToProps, client.actions
)(ClientPayments);