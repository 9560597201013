import React, {Component} from 'react';
import {Form, Formik} from "formik";
import {createAccountInitialValues, createAccountValidationSchema} from "../forms/values/createaccount.values";
import {createClient} from "../crud/client.crud";
import {createAccountProposalSubmit, decryptHash} from "../crud/auth.crud";
import SignProposal from "./SignProposal";
import {errorToast, successToast} from "../_utils/Toasts";
import BaseAccountInfoForm from "../forms/components/BaseAccountInfoForm";
import {history} from "../store/store";
import {connect} from "react-redux";
import * as auth from "../store/ducks/auth.duck";

class CreateAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: false,
            data: null,
            showCodeInput: false
        }
    }

    componentDidMount() {
        const hash = this.props.match ? this.props.match.params.hash : null
        if (hash) {
            this.decrypt(hash);
        }
    }

    decrypt(hash) {
        decryptHash(hash)
            .then(res => {
                const data = res.data.data;
                this.setState({data: data, loading: false})
                if (data.access && data.refresh) {
                    this.props.fulfillUser('', '', true, data.access, data.refresh)
                }
            })
            .catch(err => this.setState({error: true}))
    }

    submit = (values) => {
        createAccountProposalSubmit(values)
            .then(res => {
                successToast('Wniosek został podpisany.');
                history.push('/login/');
            })
            .catch(err => errorToast('Nie znaleziono wniosku.'))
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return (
                <div style={{textAlign: 'center'}}>
                    <p>Link wygasł.</p>
                </div>
            )
        }

        if (loading) {
            return (
                <div style={{textAlign: 'center'}}>
                    <div className="spinner-border text-danger"/>
                </div>
            )
        }

        return (
            <div className="container box-content rfc-login-container">
                <div className="row">
                    <p className="rfc-p">Stwórz nowe konto</p>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={createAccountInitialValues(data)}
                    validationSchema={createAccountValidationSchema}
                    onSubmit={(values, {setSubmitting}) => {
                        createClient(values)
                            .then(res => {
                                const { data } = res;
                                this.setState({data: data})
                                if (data.access && data.refresh) {
                                    this.props.fulfillUser('', '', true, data.access, data.refresh)
                                }
                            })
                            .catch(_ => errorToast('Coś poszło nie tak. Spróbuj ponownie później.'))
                    }}
                >
                    {(formik) => (
                        <Form autoComplete="off">
                            <fieldset disabled={(data && data.details) || formik.isSubmitting}>
                                <BaseAccountInfoForm formik={formik}/>
                                    <div className="row">
                                        <button type="submit"
                                                disabled={formik.isSubmitting}
                                                hidden={(this.state.showCodeInput || (data && data.details && data.proposal))}
                                                className="btn btn-primary rfc-button rfc-button-login">Stwórz nowe konto
                                        </button>
                                    </div>
                            </fieldset>
                        </Form>
                    )}
                </Formik>
                {(data && data.details && data.proposal) && (
                    <SignProposal
                        proposal={data.proposal}
                        phone={data.phone}
                        submit={this.submit}/>
                )}
            </div>
        )
    }
}

export default connect(
    null, auth.actions
)(CreateAccount);