import React, {useEffect, useState} from 'react';
import {Route} from 'react-router-dom';
import {connect} from "react-redux";
import {smsCodeValidationSchema} from "../forms/values/changephone.values";
import {Field, Form, Formik} from "formik";
import {FormikInputText} from "../forms/components/FormikInputText";
import * as auth from "../store/ducks/auth.duck";
import axios from "axios";
import {PROPOSAL_GENERATE_ACCESS_CODE_URL, PROPOSAL_IS_VALID_URL} from "../_config/config";

const Auth = (props) => {
    const [loading, setLoading] = useState(true);
    const [valid, setValid] = useState(false);
    const [errorWithCode, setErrorWithCode] = useState(false);

    useEffect(() => {
        const {proposalHash} = props.computedMatch.params;
        checkProposalValid(proposalHash)
            .then(res => {
                setValid(res.data.is_valid);
                setLoading(false);
            })
            .catch(_ => {
                setValid(false);
                setLoading(false);
            })

        if (valid) {
            getAccessCode(proposalHash)
                .then()
                .catch(err => {
                    if (err && err.response.status === 500) {
                        setErrorWithCode(true)
                    }
                })
        }
    }, [props.computedMatch.params, valid])

    function checkProposalValid(hash) {
        return axios.get(PROPOSAL_IS_VALID_URL + hash + '/')
    }

    function getAccessCode(hash) {
        return axios.post(PROPOSAL_GENERATE_ACCESS_CODE_URL, {proposal_hash: hash})
    }

    function submit(code) {
        const {proposalHash} = props.computedMatch.params;
        props.proposalAccess(proposalHash, code);
    }

    return (
        (valid && !errorWithCode) ? (
            <div className="container box-content">
                <p style={{textAlign: 'center'}}>
                    Krok 1. Wstępna weryfikacja. Wpisz poniżej kod SMS-1.
                </p>
                <Formik
                    initialValues={{sms_code: ''}}
                    initialTouched={{sms_code: true}}
                    validationSchema={smsCodeValidationSchema}
                    onSubmit={(values) => {
                        submit(values.sms_code);
                    }}
                >
                    {(formik) => (
                        <Form autoComplete="off">
                            <div className="row">
                                <div className="col-md-12">
                                    <Field
                                        name="sms_code"
                                        component={FormikInputText}
                                        label="Kod weryfikacyjny"/>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary rfc-button rfc-button-login">
                                    Potwierdzam moją tożsamość
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        ) : (
            loading ? (
                <div style={{textAlign: 'center'}}>
                    <div className="spinner-border text-danger"/>
                </div>
            ) : (
                <p style={{textAlign: 'center'}}>Niepoprawne ID wniosku lub wniosek wygasł.</p>
            )
        )
    )
}

const GainAccessRoute = ({isLoggedIn, ...props}) => {
    return isLoggedIn ? <Route {...props}/> : <Auth {...props}/>
}


const mapStateToProps = state => {
    return {
        isLoggedIn: !!state.auth.code
    }
}

export default connect(mapStateToProps, auth.actions)(GainAccessRoute)