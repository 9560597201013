import React, {Component} from 'react';
import './App.css';
import Main from "./app/pages/Main";
import { ConnectedRouter } from 'connected-react-router'
import {Provider} from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, {persistor} from "./app/store/store";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {history} from "./app/store/store";

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <ConnectedRouter history={history}>
                        <ToastContainer/>
                        <Main/>
                    </ConnectedRouter>
                </PersistGate>
            </Provider>
        );
    }
}

export default App;
