import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {
    getClientData,
    getClientInvoices,
    getClientPayments,
    getClientProposals, getClientTransactions,
    getDocuments,
    statusPayment
} from "../../crud/client.crud";
import {delay} from "@redux-saga/core/effects";

export const actionTypes = {
    Fetching: "FETCHING CLIENT...",
    ClientGet: "[client] Get",
    ClientLoaded: "[client] Loaded",
    InvoicesGet: "[client] Invoices get",
    InvoicesLoaded: "[client] Invoices loaded",
    PaymentsGet: "[client] Payments get",
    PaymentsLoaded: "[client] Payments loaded",
    PaymentMade: "[Payment] new payment",
    CheckPaymentStatus: "[Payment] check status",
    PaymentUpdateStatus: "[Payment] update status",
    DocumentsGet: "[Documents] Get",
    DocumentsLoaded: "[Documents] Loaded",
    ProposalsGet: "[Proposals] Get",
    ProposalsLoaded: "[Proposals] Loaded",
    TransactionsGet: "[Transactions] Get",
    TransactionsLoaded: "[Transactions] Loaded",
    SetSettings: "[Settings] Set"
};

const initialClientState = {
    client: { data: null, loading: true },
    invoices: { data: Array(0), page: 1, pageSize: 5, loading: true },
    payments: { data: Array(0), page: 1, pageSize: 5, loading: true },
    proposals: { data: Array(0), page: 1, pageSize: 5, loading: true },
    transactions: { data: Array(0), page: 1, pageSize: 5, loading: true },
    pendingPayment: { paymentId: null, status: null, url: null },
    documents: { data: Array(0) },
};

export const reducer = persistReducer(
    { storage, key: "panel-client" },
    (state = initialClientState, action) => {
        switch (action.type) {
            case actionTypes.Fetching: {
                const { store } = action.payload;
                if (store === 'invoices') return { ...state, invoices: { ...state.invoices, loading: true } }
                if (store === 'payments') return { ...state, payments: { ...state.payments, loading: true } }
                if (store === 'proposals') return { ...state, proposals: { ...state.proposals, loading: true } }
                if (store === 'transactions') return { ...state, transactions: { ...state.transactions, loading: true } }
                break;
            }

            case actionTypes.ClientLoaded: {
                const { client } = action.payload;
                if (client) {
                    return { ...state, client: { ...client.data, loading: false } };
                }
                return { ...state, client: { data: null, loading: true } };
            }

            case actionTypes.InvoicesLoaded: {
                const { invoices, page, pageSize } = action.payload;
                if (invoices) {
                    return { ...state, invoices: { ...invoices.data, page: page, pageSize: pageSize, loading: false } };
                }
                return { ...state, invoices: { ...initialClientState.invoices } }
            }

            case actionTypes.PaymentsLoaded: {
                const { payments, page, pageSize } = action.payload;
                if (payments) {
                    return { ...state, payments: { ...payments.data, page: page, pageSize: pageSize, loading: false } };
                }
                return { ...state, payments: { ...initialClientState.payments } }
            }

            case actionTypes.DocumentsLoaded: {
                const { documents } = action.payload;
                if (documents) {
                    return { ...state, documents: { data: documents } }
                }
                return { ...state, documents: { ...initialClientState.documents } }
            }

            case actionTypes.PaymentMade: {
                const { paymentId, status, url } = action.payload;
                return { ...state, pendingPayment: { paymentId: paymentId, status: status, url: url } }
            }

            case actionTypes.PaymentUpdateStatus: {
                const { paymentId, status } = action.payload;
                return { ...state, pendingPayment: { ...state.pendingPayment, paymentId: paymentId, status: status } }
            }

            case actionTypes.ProposalsLoaded: {
                const { proposals, page, pageSize } = action.payload;
                if (proposals) {
                    return { ...state, proposals: { ...proposals, page: page, pageSize: pageSize, loading: false } }
                }
                return { ...state, proposals: { ...initialClientState.proposals } }
            }

            case actionTypes.TransactionsLoaded: {
                const { transactions, page, pageSize } = action.payload;
                if (transactions) {
                    return { ...state, transactions: { ...transactions, page: page, pageSize: pageSize, loading: false } }
                }
                return { ...state, transactions: { ...initialClientState.transactions } }
            }

            case actionTypes.SetSettings: {
                const { settings } = action.payload;
                return { ...state, client: { ...state.client, data: { ...state.client.data, ...settings } } }
            }

            default:
                return state;
        }
    }
);

export const actions = {
    fetching: (store) => ({ type: actionTypes.Fetching, payload: { store } }),
    getClient: (code) => ({ type: actionTypes.ClientGet, payload: { code } }),
    fulfillClient: (client) => ({ type: actionTypes.ClientLoaded, payload: { client } }),
    getInvoices: (page, pageSize, filters) => ({ type: actionTypes.InvoicesGet, payload: { page, pageSize, filters } }),
    fulfillInvoices: (invoices, page, pageSize) => ({ type: actionTypes.InvoicesLoaded, payload: { invoices, page, pageSize } }),
    getPayments: (page, pageSize) => ({ type: actionTypes.PaymentsGet, payload: { page, pageSize } }),
    fulfillPayments: (payments, page, pageSize) => ({ type: actionTypes.PaymentsLoaded, payload: { payments, page, pageSize } }),
    fulfillPendingPayment: (paymentId, status, url) => ({ type: actionTypes.PaymentMade, payload: { paymentId, status, url } }),
    checkPaymentStatus: (paymentId) => ({ type: actionTypes.CheckPaymentStatus, payload: { paymentId } }),
    updatePaymentStatus: (paymentId, status) => ({ type: actionTypes.PaymentUpdateStatus, payload: { paymentId, status } }),
    getDocuments: (document_type) => ({ type: actionTypes.DocumentsGet, payload: { document_type } }),
    fulfillDocuments: (documents) => ({ type: actionTypes.DocumentsLoaded, payload: { documents } }),
    getProposals: (page, pageSize) => ({ type: actionTypes.ProposalsGet, payload: { page, pageSize } }),
    fulfillProposals: (proposals, page, pageSize) => ({ type: actionTypes.ProposalsLoaded, payload: { proposals, page, pageSize } }),
    getTransactions: (page, pageSize) => ({ type: actionTypes.TransactionsGet, payload: { page, pageSize } }),
    fulfillTransactions: (transactions, page, pageSize) => ({ type: actionTypes.TransactionsLoaded, payload: { transactions, page, pageSize } }),
    setSettings: (settings) => ({ type: actionTypes.SetSettings, payload: { settings } })
};

export function* saga() {
    yield takeLatest(actionTypes.ClientGet, function* getClientSaga(data) {
        const { code } = data.payload;
        const client = yield getClientData(code);
        yield put(actions.fulfillClient(client));
    });

    yield takeLatest(actionTypes.InvoicesGet, function* getInvoicesSaga(data) {
        yield put(actions.fetching('invoices'));
        const {page, pageSize, filters} = data.payload;
        const invoices = yield getClientInvoices(page, pageSize, filters);
        yield put(actions.fulfillInvoices(invoices, page, pageSize));
    });

    yield takeLatest(actionTypes.PaymentsGet, function* getPaymentsSaga(data) {
        yield put(actions.fetching('payments'));
        const {page, pageSize} = data.payload;
        const payments = yield getClientPayments(page, pageSize);
        yield put(actions.fulfillPayments(payments, page, pageSize));
    });

    yield takeLatest(actionTypes.DocumentsGet, function* getDocumentsSaga(data) {
        const { document_type } = data.payload;
        const documents = yield getDocuments(document_type);
        if (documents) {
            let { data: { data: { results: docs } } } = documents;
            yield put(actions.fulfillDocuments(docs));
            // TODO fix yield delay freezing all requests
            while (docs.filter(document => document.status === 'PENDING').length) {
                yield delay(3000)
                yield put(actions.getDocuments(document_type));
            }
        }
    });

    yield takeLatest(actionTypes.CheckPaymentStatus, function* checkPaymentStatusSaga(data) {
        const { paymentId } = data.payload;
        let {data: {data: payment}} = yield statusPayment(paymentId);

        const transactionStatus = ['NEW', 'PENDING']

        while (transactionStatus.includes(payment.status)) {
            yield delay(30000);

            const {data: {data: paymentData}} = yield statusPayment(paymentId);
            payment = paymentData;
        }
        yield put(actions.updatePaymentStatus(payment.paymentId, payment.status));
    });

    yield takeLatest(actionTypes.ProposalsGet, function* getProposalsSaga(data) {
        yield put(actions.fetching('proposals'));
        const { page, pageSize } = data.payload;
        const proposals = yield getClientProposals(page, pageSize);
        yield put(actions.fulfillProposals(proposals, page, pageSize));
    });

    yield takeLatest(actionTypes.TransactionsGet, function* getTransactionsSaga(data) {
        yield put(actions.fetching('transactions'));
        const { page, pageSize } = data.payload;
        const transactions = yield getClientTransactions(page, pageSize);
        yield put(actions.fulfillTransactions(transactions, page, pageSize));
    });

}
