import React, {Component} from 'react';

export default class Balance extends Component {
    render() {

        const balance = this.props.balance;

        if (typeof balance === 'number') {
            if (!balance || balance >= 0) {
                return <span style={{color: 'green', fontWeight: 'bold'}}><b>{balance.toFixed(2)} PLN</b></span>
            }
            return <span style={{color: 'red', fontWeight: 'bold'}}><b>{balance.toFixed(2)} PLN</b></span>
        } else {
            return <span style={{color: 'red', fontWeight: 'bold'}}>---</span>
        }

    }
}