import React, {Component} from 'react';
import {Field, Form, Formik} from "formik";
import {FormikInputText} from "../forms/components/FormikInputText";
import axios from "axios";
import {REQUEST_NEW_ACCOUNT_URL} from "../_config/config";
import {Link} from "react-router-dom";
import {errorToast, successToast} from "../_utils/Toasts";
import {codeValidationSchema} from "../forms/values/login.values";
import {history} from "../store/store";

export default class NewAccount extends Component {
    newAccount(code) {
        return axios.post(REQUEST_NEW_ACCOUNT_URL, {code: code})
    }

    render() {
        return (
            <div className="container box-content rfc-login-container">
                <div className="row">
                    <p className="rfc-p">Stwórz nowe konto</p>
                    <div className="col-md-12">
                        <span>Wprowadź swój numer klienta, a następnie kliknij przycisk <b>Stwórz nowe konto</b>. Na podany przez Ciebie numer telefonu otrzymasz tymczasowe hasło do swojego konta.</span>
                    </div>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{code: ''}}
                    initialTouched={{code: true}}
                    validationSchema={codeValidationSchema}
                    onSubmit={(values, actions) => {
                        actions.setSubmitting(true);
                        setTimeout(() => {
                            this.newAccount(values.code)
                                .then(( data ) => {
                                    if (data.status === 204) {
                                        errorToast('Błędny numer klienta.')
                                    } else {
                                        successToast('Na podany w umowie numer telefonu zostało wysłane tymczasowe hasło do Twojego konta.')
                                        history.push({
                                            pathname: '/login',
                                            newAccountCode: values.code
                                        })
                                    }
                                })
                                .catch((err) => {
                                    if (err.response.status === 400) {
                                        errorToast('Błędny numer klienta lub konto już istnieje w systemie.')
                                    } else {
                                        errorToast('Coś poszło nie tak. Spróbuj ponownie później.')
                                    }
                                    actions.setSubmitting(false);
                                });
                        }, 1000);
                    }}
                >{(props) => (
                    <Form>
                        <div className="row">
                            <div className="col-md-12">
                                <Field name="code" component={FormikInputText} label="Numer klienta"/>
                            </div>
                            <div className="col-md-12">
                                <Link to="/login/">
                                    <span className="rfc-p-forgot-password">Wróć do strony logowania</span>
                                </Link>
                            </div>
                            <button type="submit"
                                    disabled={props.isSubmitting}
                                    className="btn btn-primary rfc-button rfc-button-login">Stwórz nowe konto</button>
                        </div>
                    </Form>
                )}
                </Formik>
            </div>
        )
    }
}