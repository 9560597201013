import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";

class Header extends Component {
    render() {
        return (
            <header>
                <div className="navbar-contact">
                    <a className="navbar-contact-left navbar-contact-a" href="tel:+48525511333">
                        <svg height="5.09mm" viewBox="0 0 14.37 14.44" width="5.07mm"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m2.91 9.6a15.36 15.36 0 0 0 5.1 4 8 8 0 0 0 2.85.84h.2a2.35 2.35 0 0 0 1.83-.79 8.21 8.21 0 0 1 .58-.6l.42-.42a1.45 1.45 0 0 0 .11-2l-.11-.11-1.8-1.8a1.47 1.47 0 0 0 -1.09-.54 1.52 1.52 0 0 0 -1 .48l-1.09 1.08-.3-.16-.33-.18a11.34 11.34 0 0 1 -2.71-2.46 6.36 6.36 0 0 1 -.91-1.46c.28-.26.54-.53.8-.79l.27-.27a1.41 1.41 0 0 0 .16-2l-.16-.16-.89-.89-.3-.31c-.2-.2-.4-.41-.61-.6a1.47 1.47 0 0 0 -1-.46 1.57 1.57 0 0 0 -1.11.46l-1.12 1.12a2.3 2.3 0 0 0 -.7 1.48 5.61 5.61 0 0 0 .43 2.39 14 14 0 0 0 2.48 4.15zm-2.17-6.48a1.58 1.58 0 0 1 .48-1l1.11-1.12a.82.82 0 0 1 .56-.27.82.82 0 0 1 .54.27c.2.19.38.38.59.59l.31.32.89.89a.7.7 0 0 1 .14 1l-.14.14-.28.28c-.28.28-.54.55-.82.8a.6.6 0 0 0 -.12.65 7.15 7.15 0 0 0 1 1.73 11.82 11.82 0 0 0 2.89 2.6 3 3 0 0 0 .4.21l.33.18a.57.57 0 0 0 .73-.13l1.15-1.06a.79.79 0 0 1 .55-.27.73.73 0 0 1 .53.27l1.8 1.8a.72.72 0 0 1 .11 1 .63.63 0 0 1 -.12.11 5 5 0 0 1 -.4.4c-.22.21-.43.43-.62.65a1.63 1.63 0 0 1 -1.29.55h-.15a7.07 7.07 0 0 1 -2.58-.77 14.61 14.61 0 0 1 -4.85-3.8 13.23 13.23 0 0 1 -2.36-3.93 4.85 4.85 0 0 1 -.38-2.09z"
                                fill="#333" transform="translate(-.01)"></path>
                        </svg>
                        +48 52 55 11 333</a>
                    <a className="navbar-contact-a" href="mailto:internet@rfc.pl">
                        <svg height="4.02mm" viewBox="0 0 14.95 11.39" width="5.28mm"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m13.64 0h-12.33a1.31 1.31 0 0 0 -1.31 1.31v8.77a1.32 1.32 0 0 0 1.31 1.32h12.33a1.32 1.32 0 0 0 1.36-1.32v-8.77a1.31 1.31 0 0 0 -1.36-1.31zm-.18.88-6 6-5.95-6zm-12.58 9.02v-8.4l4.22 4.18zm.62.62 4.22-4.22 1.48 1.46a.44.44 0 0 0 .62 0l1.44-1.44 4.2 4.2zm12.58-.62-4.2-4.2 4.2-4.2z"
                                fill="#333"></path>
                        </svg>
                        internet@rfc.pl</a>
                </div>
                <div className="navbar-logo">
                    <Link className="navbar-logo-brand" to="/">
                        <span className="sr-only" id="navbar-logo">RFC</span>
                        <svg aria-labelledby="navbar-logo" height="50" viewBox="0 0 138.658 50"
                             width="138.658" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m101.035 96.714c5.337-.833 14.624-4.461 17.572-13.695a15.654 15.654 0 0 0 -8.774-18.7c-3.422-1.6-6.6-1.689-8.511.61s-1.026 4.957.784 8.1c1.524 2.651 4.794 10.496-2.466 18.671-1 1.125-1.779 2.242-1.521 3.38s1.251 1.895 2.916 1.634z"
                                fill="#d9000d" transform="translate(-76.824 -50.725)"></path>
                            <path
                                d="m103.255 55.585c-5.337.833-14.624 4.463-17.572 13.7a15.652 15.652 0 0 0 8.774 18.694c3.422 1.6 6.6 1.691 8.511-.61s1.026-4.957-.784-8.1c-1.525-2.646-4.79-10.491 2.465-18.669 1-1.125 1.779-2.24 1.521-3.38s-1.25-1.896-2.915-1.635z"
                                fill="#d9000d" transform="translate(-85.039 -55.534)"></path>
                            <g fill="#161615">
                                <path
                                    d="m130.638 62.507a18.015 18.015 0 0 1 4.807.589 10.4 10.4 0 0 1 3.59 1.715 7.624 7.624 0 0 1 2.252 2.751 8.415 8.415 0 0 1 .786 3.7 7.98 7.98 0 0 1 -1.43 4.823 8.871 8.871 0 0 1 -4.109 3l5.9 8.433h-5.9l-5.252-7.5h-8.325v7.5h-5.182v-25.011zm-7.681 2.679v12.149h7.681a6.027 6.027 0 0 0 4.362-1.482 6.13 6.13 0 0 0 1.536-4.591 6.13 6.13 0 0 0 -1.536-4.592 6.027 6.027 0 0 0 -4.36-1.484z"
                                    transform="translate(-64.399 -51.137)"></path>
                                <path
                                    d="m143.05 65.186v8.576h13.042v2.679h-13.042v11.078h-5.18v-25.012h22.511v2.679z"
                                    transform="translate(-51.729 -51.137)"></path>
                                <path
                                    d="m168.791 85.829a12.6 12.6 0 0 0 8.934-3.572l1.785 2.143a15.044 15.044 0 0 1 -4.931 3 17.075 17.075 0 0 1 -6.145 1.107 19.934 19.934 0 0 1 -6.057-.858 12.7 12.7 0 0 1 -4.609-2.537 11.219 11.219 0 0 1 -2.948-4.145 14.578 14.578 0 0 1 -1.035-5.681 14.772 14.772 0 0 1 1.019-5.682 11.221 11.221 0 0 1 2.894-4.145 12.434 12.434 0 0 1 4.52-2.537 18.951 18.951 0 0 1 5.86-.858 17.041 17.041 0 0 1 6.145 1.109 15.024 15.024 0 0 1 4.931 3l-1.785 2.144a12.619 12.619 0 0 0 -8.934-3.572 10.276 10.276 0 0 0 -3.608.624 7.562 7.562 0 0 0 -2.9 1.913 9.075 9.075 0 0 0 -1.911 3.269 16.444 16.444 0 0 0 0 9.468 8.683 8.683 0 0 0 1.965 3.271 7.95 7.95 0 0 0 3 1.911 11.132 11.132 0 0 0 3.81.628z"
                                    transform="translate(-41.695 -51.414)"></path>
                                <path d="m117.877 83.823h.838v3.809h-.838z"
                                      transform="translate(-64.335 -37.698)"></path>
                                <path
                                    d="m119.074 83.823h.773l1.784 2.345v-2.345h.827v3.809h-.713l-1.845-2.421v2.421h-.827z"
                                    transform="translate(-63.58 -37.698)"></path>
                                <path d="m121.826 83.823h2.9v.761h-2.061v.81h1.816v.763h-1.816v1.474h-.838z"
                                      transform="translate(-61.845 -37.698)"></path>
                                <path
                                    d="m123.946 85.764v-.011a2.025 2.025 0 0 1 4.049-.011v.011a2.025 2.025 0 0 1 -4.049.011zm3.171 0v-.011a1.157 1.157 0 0 0 -1.153-1.2 1.139 1.139 0 0 0 -1.143 1.185v.011a1.158 1.158 0 0 0 1.154 1.2 1.139 1.139 0 0 0 1.142-1.185z"
                                    transform="translate(-60.508 -37.723)"></path>
                                <path
                                    d="m126.983 83.823h1.741a1.517 1.517 0 0 1 1.109.386 1.191 1.191 0 0 1 .328.871v.01a1.185 1.185 0 0 1 -.817 1.182l.931 1.36h-.98l-.815-1.22h-.659v1.22h-.838zm1.686 1.849c.409 0 .642-.217.642-.538v-.01c0-.36-.249-.545-.657-.545h-.833v1.092z"
                                    transform="translate(-58.594 -37.698)"></path>
                                <path
                                    d="m129.507 83.823h.9l1 1.611 1-1.611h.9v3.809h-.833v-2.487l-1.071 1.626h-.023l-1.06-1.609v2.47h-.822z"
                                    transform="translate(-57.002 -37.698)"></path>
                                <path
                                    d="m133.916 83.806h.771l1.632 3.837h-.876l-.349-.854h-1.609l-.349.854h-.853zm.88 2.242-.505-1.234-.505 1.234z"
                                    transform="translate(-55.251 -37.708)"></path>
                                <path d="m135.943 84.6h-1.159v-.773h3.155v.773h-1.158v3.036h-.838z"
                                      transform="translate(-53.675 -37.698)"></path>
                                <path d="m137.19 83.823h.838v3.809h-.838z"
                                      transform="translate(-52.158 -37.698)"></path>
                                <path
                                    d="m138.269 85.764v-.011a2.024 2.024 0 0 1 4.047-.011v.011a2.024 2.024 0 0 1 -4.047.011zm3.171 0v-.011a1.157 1.157 0 0 0 -1.153-1.2 1.139 1.139 0 0 0 -1.143 1.185v.011a1.158 1.158 0 0 0 1.153 1.2 1.139 1.139 0 0 0 1.143-1.185z"
                                    transform="translate(-51.478 -37.723)"></path>
                                <path
                                    d="m141.306 83.823h.773l1.784 2.345v-2.345h.828v3.809h-.714l-1.844-2.421v2.421h-.827z"
                                    transform="translate(-49.563 -37.698)"></path>
                                <path d="m145.937 84.6h-1.159v-.773h3.155v.773h-1.158v3.036h-.838z"
                                      transform="translate(-47.374 -37.698)"></path>
                                <path
                                    d="m147.171 83.823h2.873v.745h-2.044v.773h1.8v.745h-1.8v.8h2.066v.745h-2.9z"
                                    transform="translate(-45.865 -37.698)"></path>
                                <path
                                    d="m149.385 85.764v-.011a1.935 1.935 0 0 1 1.986-1.97 1.97 1.97 0 0 1 1.5.587l-.535.615a1.405 1.405 0 0 0 -.973-.429 1.128 1.128 0 0 0 -1.1 1.185v.011a1.131 1.131 0 0 0 1.1 1.2 1.373 1.373 0 0 0 1-.447l.533.54a1.946 1.946 0 0 1 -1.562.68 1.922 1.922 0 0 1 -1.949-1.961z"
                                    transform="translate(-44.469 -37.723)"></path>
                                <path
                                    d="m152.024 83.823h.838v1.507h1.546v-1.507h.838v3.809h-.838v-1.532h-1.546v1.529h-.838z"
                                    transform="translate(-42.805 -37.698)"></path>
                                <path
                                    d="m154.676 83.823h.773l1.784 2.345v-2.345h.828v3.809h-.714l-1.847-2.421v2.421h-.827z"
                                    transform="translate(-41.133 -37.698)"></path>
                                <path
                                    d="m157.311 85.764v-.011a2.024 2.024 0 0 1 4.047-.011v.011a2.024 2.024 0 0 1 -4.047.011zm3.171 0v-.011a1.157 1.157 0 0 0 -1.154-1.2 1.139 1.139 0 0 0 -1.141 1.185v.011a1.158 1.158 0 0 0 1.153 1.2 1.139 1.139 0 0 0 1.142-1.185z"
                                    transform="translate(-39.472 -37.723)"></path>
                                <path d="m160.348 83.823h.838v3.047h1.9v.761h-2.736z"
                                      transform="translate(-37.557 -37.698)"></path>
                                <path
                                    d="m162.343 85.764v-.011a2.025 2.025 0 0 1 4.049-.011v.011a2.025 2.025 0 0 1 -4.049.011zm3.171 0v-.011a1.157 1.157 0 0 0 -1.153-1.2 1.139 1.139 0 0 0 -1.143 1.185v.011a1.158 1.158 0 0 0 1.154 1.2 1.139 1.139 0 0 0 1.142-1.185z"
                                    transform="translate(-36.299 -37.723)"></path>
                                <path
                                    d="m165.263 85.764v-.011a1.956 1.956 0 0 1 2-1.97 2.094 2.094 0 0 1 1.5.522l-.528.638a1.405 1.405 0 0 0 -1-.386 1.143 1.143 0 0 0 -1.092 1.185v.011a1.141 1.141 0 0 0 1.153 1.208 1.333 1.333 0 0 0 .788-.228v-.545h-.843v-.724h1.655v1.653a2.466 2.466 0 0 1 -1.627.6 1.907 1.907 0 0 1 -2.006-1.953z"
                                    transform="translate(-34.458 -37.723)"></path>
                                <path
                                    d="m169.233 86.13-1.463-2.307h.978l.908 1.529.926-1.529h.952l-1.464 2.291v1.518h-.838z"
                                    transform="translate(-32.877 -37.698)"></path>
                            </g>
                        </svg>
                    </Link>
                    {this.props.code && (
                        <div className="navbar-logout">
                            <Link to="/logout"><i className="fa fa-power-off"/> Wyloguj</Link>
                        </div>
                    )}
                </div>
            </header>
        )
    }
}

const mapStateToProps = state => {
    return {
        code: state.auth.code,
    }
};

export default connect(
    mapStateToProps, null
)(Header);