import React, {Component} from 'react';

export default class WarningIcon extends Component {
    render() {
        return (
            <div className="f-modal-icon f-modal-warning scaleWarning">
                <span className="f-modal-body pulseWarningIns"/>
                <span className="f-modal-dot pulseWarningIns"/>
            </div>
        )
    }
}