import React, {Component} from 'react';
import ClientChangePassword from "./ClientChangePassword";
import ClientChangeEmail from "./ClientChangeEmail";
import ClientChangePhone from "./ClientChangePhone";
import ClientChangeSettings from "./ClientChangeSettings";

export default class ClientChangeData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: ''
        }
    }

    getSelectedForm() {
        switch(this.state.form) {
            case 'change-password': return <ClientChangePassword/>
            case 'change-email': return <ClientChangeEmail/>
            case 'change-phone': return <ClientChangePhone/>
            case 'settings': return <ClientChangeSettings/>
            default: return null
        }
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-md-3">
                        <button
                            className="btn btn-primary rfc-button"
                            onClick={() => this.setState({form: 'change-password'})}>Zmień hasło</button>
                    </div>
                    <div className="col-md-3">
                        <button
                            className="btn btn-primary rfc-button"
                            onClick={() => this.setState({form: 'change-email'})}>Zmień e-mail</button>
                    </div>
                    <div className="col-md-3">
                        <button
                            className="btn btn-primary rfc-button"
                            onClick={() => this.setState({form: 'change-phone'})}>Zmień nr telefonu</button>
                    </div>
                    <div className="col-md-3">
                        <button
                            className="btn btn-primary rfc-button"
                            onClick={() => this.setState({form: 'settings'})}>Ustawienia</button>
                    </div>
                </div>
                <div className="row">
                    {this.getSelectedForm()}
                </div>
            </>
        )
    }
}