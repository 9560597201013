import React, {Component} from 'react';

export default class ClientProfile extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    {/*<Formik*/}
                    {/*    initia*/}
                    {/*>*/}
                    {/*    */}
                    {/*</Formik>*/}
                </div>
            </div>
        )
    }
}