import React from "react";
import LabelledText from "../../_common/LabelledText";
import SignProposal from "../../SignProposal";

export default function SignChangePhone(props) {
    return (
        <div className="row">
            <div className="col-md-12">
                <LabelledText text={props.proposal.json.phone} label={'Nowy numer telefonu'}/>
            </div>
            <div className="col-md-12">
                <SignProposal header={'Zmień numer telefonu'}
                              buttonConfirmText={'Zapisz'}
                              buttonRejectText={'Anuluj'}
                              proposal={props.proposal}
                              phone={props.proposal.json.phone}/>
            </div>
        </div>
    )
}