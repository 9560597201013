import React, {Component} from 'react';
import {Field} from "formik";
import {TextField} from "formik-material-ui";
import AddressForm from "../../pages/_common/AddressForm";
import {Checkbox, FormControlLabel} from "@material-ui/core";

export default class BaseAccountInfoForm extends Component {
    onTypeChange = (type, formik) => {
        formik.setFieldValue('type', type)
    }

    render() {
        const { formik } = this.props;

        return (
            <>
                <div className="row">
                    <div className="col-md-6 col-xs-12">
                        <button type="button"
                                onClick={() => this.onTypeChange(0, formik)}
                                className={"btn btn-primary rfc-button rfc-button-small"
                                + (formik.values.type === 0 ? ' selected' : '')}>
                            Osoba fizyczna
                        </button>
                    </div>
                    <div className="col-md-6 col-xs-12" style={{marginBottom: '15px'}}>
                        <button type="button"
                                onClick={() => this.onTypeChange(1, formik)}
                                className={"btn btn-primary rfc-button rfc-button-small"
                                + (formik.values.type === 1 ? ' selected' : '')}>
                            Firma
                        </button>
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <Field
                            name="first_name"
                            component={TextField}
                            label="Imię"
                            style={{width: '100%'}}
                            disabled
                        />
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <Field
                            name="last_name"
                            component={TextField}
                            label="Nazwisko"
                            style={{width: '100%'}}
                            disabled
                        />
                    </div>
                    <div className="col-md-6 col-xs-6">
                        <Field
                            name="phone"
                            component={TextField}
                            label="Nr telefonu"
                            style={{width: '100%'}}
                            disabled
                        />
                    </div>
                    <div className="col-md-6 col-xs-6">
                        <Field
                            name="email"
                            component={TextField}
                            label="E-mail"
                            style={{width: '100%'}}
                            disabled
                        />
                    </div>
                    {formik.values.type === 0 && (
                        <div className="col-md-12 col-xs-12">
                            <Field
                                name="pesel"
                                component={TextField}
                                label="Pesel"
                                style={{width: '100%'}}
                            />
                        </div>
                    )}
                    {formik.values.type === 1 && (
                        <>
                            <div className="col-md-12 col-xs-12">
                                <Field
                                    name="name"
                                    component={TextField}
                                    label="Nazwa firmy"
                                    style={{width: '100%'}}
                                />
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <Field
                                    name="nip"
                                    component={TextField}
                                    label="NIP"
                                    style={{width: '100%'}}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div className="row">
                    <p style={{marginTop: '15px'}}>Adres podstawowy</p>
                    <AddressForm form={'base_address'} formik={formik}/>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="is_correspondence_different"
                                    checked={formik.values.is_correspondence_different}
                                    onChange={event => {
                                        formik.setFieldValue('is_correspondence_different', event.target.checked)
                                    }}
                                    color="primary"/>
                            }
                            label="Adres korespondencji inny niż adres podstawowy"
                        />
                    </div>
                </div>
                {formik.values.is_correspondence_different && (
                    <div className="row">
                        <p>Adres korespondencji</p>
                        <AddressForm form={'correspondence'} formik={formik}/>
                    </div>
                )}
            </>
        )
    }
}