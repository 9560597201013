import React, {Component} from 'react';
import {Field, Form, Formik} from "formik";
import {FormikInputText} from "../forms/components/FormikInputText";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import * as auth from "../store/ducks/auth.duck";
import {codeValidationSchema} from "../forms/values/login.values";
import {history} from "../store/store";
import {resetPassword} from "../crud/auth.crud";
import {errorToast} from "../_utils/Toasts";

class ResetPassword extends Component {

    render() {
        return (
            <div className="container box-content rfc-login-container">
                <div className="row">
                    <p className="rfc-p">
                        {this.props.history.location.state?.signed ? (
                            'Witaj w Strefie Klienta RFC'
                        ) : (
                          'Reset hasła'
                        )}
                    </p>
                    <div className="col-md-12" style={{textAlign: 'justify'}}>
                        {this.props.history.location.state?.signed ? (
                            <>
                                <p>
                                    Od dziś możesz, jako Abonent naszych usług, korzystać ze swojego profilu w Strefie Klienta RFC.
                                    Znajdziesz tu między innymi swoje faktury i informacje o płatnościach.
                                </p>
                                <p>
                                    Twój login do Strefy to indywidualny numer Klienta, który przesłaliśmy Ci w tej chwili w wiadomości SMS
                                    na podany przez Ciebie numer telefonu. Na początek prosimy, abyś zmienił hasło dostępu do Strefy na ustawione przez siebie.
                                    Ustawienie własnego hasła jest konieczne ze względów bezpieczeństwa.
                                </p>
                                <p>
                                    Wprowadź swój indywidualny numer Klienta poniżej, a następnie kliknij przycisk "Resetuj hasło" i postępuj według kolejnych wskazówek.
                                </p>
                            </>
                        ) : (
                            <span>Wprowadź swój indywidualny numer Klienta. Nowe hasło zostanie wysłane na podany przez Ciebie numer telefonu.</span>
                        )}
                    </div>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{code: ''}}
                    initialTouched={{code: true}}
                    validationSchema={codeValidationSchema}
                    onSubmit={(values, actions) => {
                        resetPassword(values.code)
                            .then(_ => {
                                setTimeout(() => {
                                    actions.setSubmitting(false);
                                    history.push('/login/')
                                }, 1000);
                            })
                            .catch(_ => {
                                errorToast("Błędny identyfikator.")
                                actions.setSubmitting(false);
                            })
                    }}
                >{(props) => (
                    <Form>
                        <div className="row">
                            <div className="col-md-12">
                                <Field name="code" component={FormikInputText} label="Numer Klienta"/>
                            </div>
                            <div className="col-md-12">
                                <Link to="/login/">
                                    <span className="rfc-p-forgot-password">Wróć do strony logowania</span>
                                </Link>
                            </div>
                            <button type="submit"
                                    disabled={props.isSubmitting}
                                    className="btn btn-primary rfc-button rfc-button-login">Resetuj hasło</button>
                        </div>
                    </Form>
                )}
                </Formik>
            </div>
        )
    }
}

export default connect(
    null, auth.actions
)(ResetPassword);