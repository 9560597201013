import React, {Component} from "react";
import {Field, Form, Formik} from "formik";
import {FormikInputText} from "../../forms/components/FormikInputText";
import {changeEmailValationSchema} from "../../forms/values/changeemail.values";
import axios from "axios";
import {PROPOSAL_URL} from "../../_config/config";
import {errorToast, successToast} from "../../_utils/Toasts";
import {history} from "../../store/store";

export default class ClientChangeEmail extends Component {

    patchEmail = (email) => {
        axios.post(PROPOSAL_URL, {tpe: 'CHANGE_EMAIL', json: {email: email}})
            .then(res => {
                successToast('Wniosek o zmianę adresu e-mail został złożony poprawnie.');
                history.push('/proposal/' + res.data.proposal_hash)
            })
            .catch(err => errorToast('Coś poszło nie tak.'))
    }

    render() {
        return (
            <div className="container box-content">
                <div className="row">
                    <p className="rfc-p">Zmiana e-mail</p>
                </div>
                <Formik
                    initialValues={{email: ''}}
                    initialTouched={{email: true}}
                    validationSchema={changeEmailValationSchema}
                    onSubmit={(values) => {
                        this.patchEmail(values.email)
                    }}
                >
                    {(formik) => (
                        <Form autoComplete="off">
                            <div className="row">
                                <div className="col-md-12">
                                    <Field name="email" component={FormikInputText} label="Nowy adres e-mail"/>
                                </div>
                                <button type="submit"
                                        disabled={formik.isSubmitting}
                                        className="btn btn-primary rfc-button rfc-button-login">
                                    Zmień e-mail
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}