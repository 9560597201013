import React from "react";
import SignProposal from "../../SignProposal";
import LabelledText from "../../_common/LabelledText";

export default function SignAgreementDataChange(props) {
    const { proposal } = props;

    return (
        <div className="row">
            <div className="col-md-12">
                <p style={{fontWeight: '600', fontSize: '17px', borderBottom: '1px solid #d9000d', color: '#d9000d'}}>
                    Podsumowanie - Nowe dane
                </p>
            </div>
            {proposal.json.name && (
                <div className="col-md-12">
                    <LabelledText text={proposal.json.name} label={'Nazwa firmy'}/>
                </div>
            )}
            {proposal.json.nip && (
                <div className="col-md-12">
                    <LabelledText text={proposal.json.nip} label={'NIP'}/>
                </div>
            )}
            {proposal.json.last_name && (
                <div className="col-md-12">
                    <LabelledText text={proposal.json.last_name} label={'Nazwisko'}/>
                </div>
            )}
            {proposal.json.pesel && (
                <div className="col-md-12">
                    <LabelledText text={proposal.json.pesel} label={'Pesel'}/>
                </div>
            )}
            <SignProposal proposal={proposal}/>
        </div>
    )
}