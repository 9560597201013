import React, {Component} from 'react';
import {Field, Form, Formik} from "formik";
import {CheckboxWithLabel} from "formik-material-ui";
import axios from "axios";
import {CLIENT_SETTINGS_URL} from "../../_config/config";
import {errorToast, successToast} from "../../_utils/Toasts";
import {connect} from "react-redux";
import * as client from "../../store/ducks/client.duck";

class ClientChangeSettings extends Component {
    saveSettings(values) {
        axios.post(CLIENT_SETTINGS_URL, {...values})
            .then(res => {
                successToast('Ustawienia zostały zapisane.')
                this.props.setSettings(values);
            })
            .catch(err => errorToast('Coś poszło nie tak.'))
    }

    render() {
        return (
            <div className="container box-content">
                <div className="row">
                    <p className="rfc-p">Ustawienia</p>
                </div>

                <Formik
                    initialValues={{attach_invoice: this.props.client.attach_invoice}}
                    onSubmit={(values, {setSubmitting}) => {
                        this.saveSettings(values);
                        setSubmitting(false);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <div className="row">
                                <div className="col-md-12">
                                    <Field component={CheckboxWithLabel}
                                           type="checkbox"
                                           name="attach_invoice"
                                           Label={{ label: 'Załącz fakturę do wiadomości e-mail' }}
                                    />
                                </div>
                                <button type="submit"
                                        disabled={formik.isSubmitting}
                                        className="btn btn-primary rfc-button rfc-button-login">
                                    Zapisz ustawienia
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        client: state.clientInfo.client.data
    }
};

export default connect(
    mapStateToProps, client.actions
)(ClientChangeSettings);