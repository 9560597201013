import React, {Component} from 'react';

export default class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="footer-copyright">
                    <div className="container">
                        <div className="row row-border">
                            <div className="col-sm-6 p-0">
                                <div>
                                    <a className="footer-a" href="https://rfc.pl/polityka-prywatnosci/"
                                       title="Polityka prywatności">Polityka
                                        prywatności</a> © 2004 - 2021 RFC Internet i Telewizja
                                </div>
                                <div>
                                    <a className="footer-a" href="https://rfc.pl/regulamin-platnosci/"
                                       title="Regulamin płatności">Regulamin płatności</a>
                                </div>
                            </div>
                            <div className="col-sm-6 p-0">
                                <div className="order-2">
                                    projekt i wykonanie: <a className="footer-a" target="_blank"
                                                            rel="nofollow noopener noreferrer"
                                                            href="http://rfc.pl"
                                                            title="Projekt i wykonanie www.rfc.pl">
                                    RFC.pl
                                </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}