import {boolean, object, string} from "yup";

const addressShape = object().shape({
    teryt: object().shape({
        city: object().nullable().required('Wprowadź miasto'),
    }),
    build_number: string().required('Wprowadź numer budynku'),
    has_home_number: boolean(),
    home_number: string().when('has_home_number', {
        is: true,
        then: string().required('Wprowadź numer mieszkania')
    }),
    postal_code: string().required('Wprowadź kod pocztowy')
        .matches(/^[0-9]{2}-[0-9]{3}$/, 'Kod pocztowy musi być w formacie XX-XXX')})

export const createAccountValidationSchema = object().shape({
    base_address: addressShape,
    correspondence: object().when('is_correspondence_different', {
        is: true,
        then: addressShape
    }),
    email: string().required('Wprowadź adres e-mail').email('Wprowadź poprawny adres e-mail'),
    phone: string().required('Wprowadź numer telefonu').matches(/^[0-9]{9}$/, 'Nr telefonu musi składać się z 9 cyfr'),
    pesel: string().when('type', {
        is: 0,
        then: string().required('Wprowadź pesel').matches(/^[0-9]{11}$/, 'Pesel musi się składać z 11 cyfr')
    }),
    first_name: string().when('type', {
        is: 0,
        then: string().required('Wprowadź imię')
    }),
    last_name: string().when('type', {
        is: 0,
        then: string().required('Wprowadź nazwisko')
    }),
    nip: string().when('type', {
        is: (type => type > 0),
        then: string().required('Wprowadź NIP')
    }),
    name: string().when('type', {
        is: (type => type > 0),
        then: string().required('Wprowadź nazwę')
    }),
})

export const confirmCreateAccountValidationSchema = object().shape({
    sms_code: string()
        .matches(/^[0-9]{6}$/, 'Kod musi się składać z 6 cyfr')
        .required('Wprowadź kod weryfikacyjny')
})

function compareAddresses(data) {
    if (!data) return false;
    return JSON.stringify(data.base_address) !== JSON.stringify(data.correspondence)
}

export function createAccountInitialValues(data) {
    return {
        type: (data && data.type) ? data.type : 0,
        first_name: data ? data.first_name : "",
        last_name: data ? data.last_name : "",
        name: (data && data.details && data.details.customer_1) ? data.details.customer_1.name : '',
        pesel: (data && data.pesel) ? data.pesel : "",
        nip: (data && data.nip) ? data.nip : "",
        email: data ? data.email : "",
        phone: (data && data.phone) ? data.phone : "",
        entry_id: (data && data.entry_id) ? data.entry_id: null,
        house_point: (data && data.house_point) ? data.house_point : {},
        is_correspondence_different: compareAddresses(data),
        base_address: {
            build_number: (data && data.base_address) ? data.base_address.build_number : '',
            home_number: (data && data.base_address) ? data.base_address.home_number : '',
            postal_code: (data && data.base_address) ? data.base_address.postal_code : '',
            has_home_number: (data && data.base_address) ? data.base_address.has_home_number : false,
            teryt: (data && data.base_address) ? data.base_address.teryt : {
                city: null,
                street: null,
            }
        },
        correspondence: {
            build_number: (data && data.correspondence) ? data.correspondence.build_number : '',
            home_number: (data && data.correspondence) ? data.correspondence.home_number : '',
            postal_code: (data && data.correspondence) ? data.correspondence.postal_code : '',
            has_home_number: (data && data.correspondence) ? data.correspondence.has_home_number : false,
            teryt: (data && data.correspondence) ? data.correspondence.teryt : {
                city: null,
                street: null,
            }
        },
    }
}
