import React, {Component} from 'react';
import axios from "axios";
import {HEALTH_CHECK_URL} from "../_config/config";
import {connect} from "react-redux";
import * as auth from "../store/ducks/auth.duck";
import {Redirect} from "react-router-dom";

class Maintenance extends Component {
    componentDidMount() {
        this.healthCheck();
    }

    healthCheck() {
        axios.get(HEALTH_CHECK_URL)
            .then(res => this.props.enablePage())
            .catch(err => err)
    }

    render() {
        if (!this.props.maintenance) {
            return <Redirect to="/"/>
        }

        return (
            <div>
                <p style={{fontSize: '24px', fontWeight: '600'}}>Przerwa techniczna</p>
                <p>Korzystanie z Panelu Klienta tymczasowo wyłączone.</p>
                <span>Dziękujemy za wyrozumiałość.</span><br/>
                <span>RFC</span>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        maintenance: state.auth.maintenance,
    }
};

export default connect(
    mapStateToProps, auth.actions
)(Maintenance);