import React, {Component} from 'react';
import {connect} from "react-redux";
import * as client from "../../store/ducks/client.duck";
import DataTable from "../_common/DataTable";

class ClientTransactions extends Component {

    componentDidMount() {
        const {page, pageSize} = this.props;

        this.fetchData(page, pageSize);
    }

    fetchData(page, pageSize) {
        this.props.getTransactions(page, pageSize);
    }

    onChangePage = (page, pageSize) => {
        if (page !== this.props.page) {
            this.fetchData(page, pageSize);
        }
    }

    dateFormatter = row => {
        return new Date(row.created_at).toLocaleString('pl-PL')
    }

    statusFormatter = row => {
        switch(row.status) {
            case 'Utworzona': return <span className="label label-warning">{row.status}</span>
            case 'Nowa': return <span className="label label-warning">{row.status}</span>
            case 'Oczekująca': return <span className="label label-warning">{row.status}</span>
            case 'Oczekuje na rozliczenie': return <span className="label label-warning">{row.status}</span>
            case 'Rozliczona': return <span className="label label-success">{row.status}</span>
            case 'Odrzucona': return <span className="label label-danger">{row.status}</span>
            case 'Wygasła': return <span className="label label-danger">{row.status}</span>
            case 'Błąd transakcji': return <span className="label label-danger">{row.status}</span>
            default: return row.status
        }
    }

    columns = [
        {name: 'ID transakcji', dataField: 'transaction_id'},
        {name: 'Data transakcji', dataField: 'created_at', formatter: this.dateFormatter},
        {name: 'Status', dataField: 'status', formatter: this.statusFormatter},
        {name: 'Kwota', dataField: 'amount'}
    ]

    render() {
        const {transactions, loading, page, pageSize} = this.props;

        return (
            <DataTable
                columns={this.columns}
                rows={transactions.results}
                recordsTotal={transactions.count}
                page={page}
                pageSize={pageSize}
                paginated={true}
                loading={loading}
                onChangePage={this.onChangePage}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        transactions: state.clientInfo.transactions.data,
        loading: state.clientInfo.transactions.loading,
        page: state.clientInfo.transactions.page,
        pageSize: state.clientInfo.transactions.pageSize,
    }
};

export default connect(
    mapStateToProps, client.actions
)(ClientTransactions);