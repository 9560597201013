import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Phone} from "../_common/Phone";
import Balance from "../_common/Balance";
import {bindActionCreators} from "redux";
import * as client from "../../store/ducks/client.duck";
import * as auth from "../../store/ducks/auth.duck";
import {connect} from "react-redux";
import AttachInvoice from "../_common/AttachInvoice";

const QRCode = require('qrcode.react');

class ClientInfoRender extends Component {

    qrcode = () => {
        const nip = '9532386588';
        const bank_account = this.props.client.bank_account.number.replace(/\s/g, '');
        const value = Math.abs(this.props.client.balance) * 100;
        const receiver = 'RFC Marcin Frątczak';
        const title = 'Opłata za Internet - QR';
        return `${nip}|PL|${bank_account}|${value.toString().padStart(6, '0')}|${receiver}|${title}|||`
    }

    render() {
        const {client, loading} = this.props;

        if (!client || loading) {
            return (
                <div style={{textAlign: 'center'}}>
                    <div className="spinner-border text-danger"/>
                </div>
            )
        }

        return (
            <>
                <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12 order-md-1 order-2 div-client-info">
                        <p><b>Dane klienta:</b></p>
                        <span className="span-client-info">{client.customer_1.full_name}</span>
                        {client.customer_1.address.teryt.street && client.customer_1.address.teryt.street.sym_ul !== 99999 && (
                            <span
                                className="span-client-info">{client.customer_1.address.teryt.street.name} {client.customer_1.address.build_number}{client.customer_1.address.home_number ? `/${client.customer_1.address.home_number}` : ''}</span>
                        )}
                        <span
                            className="span-client-info">{client.customer_1.address.postal_code} {client.customer_1.address.teryt.city.name} {client.customer_1.address.teryt.street ? client.customer_1.address.teryt.street.sym_ul === 99999 && (client.customer_1.address.build_number) : client.customer_1.address.build_number}</span>
                        <span className="span-client-info"><Phone phone={client.customer_1.phone}/></span>
                        <span className="span-client-info">{client.customer_1.email}</span>
                        <AttachInvoice attachInvoice={client.attach_invoice}/>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 order-md-2 order-1 div-client-info sm-mb-15">
                        <p><b>Saldo:</b></p>
                        <b><Balance balance={client.balance}/></b>
                        <p style={{marginTop: '15px'}}>Indywidualny numer rachunku:</p>
                        <b>{client.bank_account ? client.bank_account.number : ''}</b>
                        <div style={{marginTop: '15px', textAlign: 'center'}}>
                            <div>
                                <p style={{fontSize: '12px'}}>Zeskanuj kod QR w aplikacji swojego banku, aby wykonać przelew</p>
                            </div>
                            <div>
                                <QRCode value={this.qrcode()}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="div-client-info">
                        <Link to="/change/">
                            <button className="btn btn-primary rfc-button">Zmień dane</button>
                        </Link>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        code: state.auth.code,
        has_changed_password: state.auth.has_changed_password,
        client: state.clientInfo.client.data,
        loading: state.clientInfo.client.loading
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...client.actions,
            access: auth.actions.access
        }, dispatch
    );
}


export default connect(
    mapStateToProps, mapDispatchToProps
)(ClientInfoRender);