import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import * as client from "../../store/ducks/client.duck";
import * as auth from "../../store/ducks/auth.duck";
import {connect} from "react-redux";
import ClientInfoRender from "./ClientInfoRender";
import Login from "../Login";

class ClientInfoSAPAccess extends Component {
    fetchData() {
        if (this.props.match.params.code) {
            const hash = this.props.match.params.code;
            this.props.access(hash);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.code !== prevProps.code) {
            const {code} = this.props;
            this.props.getClient(code);
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        if (!this.props.client) {
            return <Login/>
        }
        return <ClientInfoRender/>
    }

}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...client.actions,
            access: auth.actions.access
        }, dispatch
    );
}

const mapStateToProps = state => {
    return {
        code: state.auth.code,
        has_changed_password: state.auth.has_changed_password,
        client: state.clientInfo.client.data,
        loading: state.clientInfo.client.loading
    }
};


export default connect(
    mapStateToProps, mapDispatchToProps
)(ClientInfoSAPAccess);