import React, {useEffect, useState} from 'react';
import {getClientProposal} from "../../crud/client.crud";
import SignAgreement from "./proposals/SignAgreement";
import SignAgreementDataChange from "./proposals/SignAgreementDataChange";
import SignChangeEmail from "./proposals/SignChangeEmail";
import SignChangePhone from "./proposals/SignChangePhone";

export default function ClientProposal(props) {
    const [proposal, setProposal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const { proposalHash } = props.match.params
        fetch(proposalHash);
    }, [props.match.params])

    const fetch = (proposalHash) => {
        getClientProposal(proposalHash)
            .then(res => {
                setProposal(res.data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                setError(true)
            })
    }

    const renderProposal = (type) => {
        if (type === "CHANGE_PHONE") return <SignChangePhone proposal={proposal}/>
        if (type === "CHANGE_EMAIL") return <SignChangeEmail proposal={proposal}/>
        if (type === "SIGN_AGREEMENT") return <SignAgreement proposal={proposal}/>
        if (type === "MODIFY_AGREEMENT_DATA") return <SignAgreementDataChange proposal={proposal}/>
    }

    if (loading) {
        return (
            <div style={{textAlign: 'center'}}>
                <div className="spinner-border text-danger"/>
            </div>
        )
    } else {
        if (proposal) {
            return (
                <div className="container box-content">
                    {renderProposal(proposal.tpe)}
                </div>
            )
        }
        return <p style={{textAlign: 'center'}}>Błędny ID wniosku lub wniosek jest już nieaktywny.</p>
    }
}