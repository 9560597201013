import React from "react";

export const FormikInputText = ({
    field,
    form: { touched, errors },
    ...props
}) => (
    <div className="input-field">
        <input className={(touched[field.name] && errors[field.name]) && 'error'}
               type="text" id={field.name} {...field} {...props} />
        <label className={(touched[field.name] && errors[field.name]) ? (!field.value ? 'error' : 'error active') : (touched[field.name] && !errors[field.name]) ? (field.value && 'active') : ''}
               htmlFor={field.name}>{props.label}</label>
        {touched[field.name] && errors[field.name] && <div className="error">{errors[field.name]}</div>}
    </div>
)