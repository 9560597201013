import React, {Component} from "react";
import axios from "axios";
import {PROPOSAL_VERIFY_URL} from "../../_config/config";
import VerificationDone from "../_common/VerificationDone";

export default class ClientVerificationEmailDone extends Component {
    constructor(props) {
        super(props);

        this.state = {
            success: false,
            error: false,
            loading: true
        }
    }

    componentDidMount() {
        const { code } = this.props.match.params;
        axios.get(PROPOSAL_VERIFY_URL + code)
            .then(res => this.setState({loading: false, success: true}))
            .catch(err => this.setState({loading: false, error: true}))
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{textAlign: 'center'}}>
                    <div className="spinner-border text-danger"/>
                </div>
            )
        }

        if (this.state.success) {
            return <VerificationDone/>
        }

        if (this.state.error) {
            return <p>Wystąpił problem z przetworzeniem Twojego wniosku. Spróbuj ponownie.
                Jeżeli sytuacja się powtórzy, skontaktuj się z Biurem Obsługi Klienta.</p>
        }
    }
}