import React, {Component} from "react";
import {connect} from "react-redux";
import * as client from "../../store/ducks/client.duck";
import DataTable from "../_common/DataTable";
import ClientProposalStatus from "./ClientProposalStatus";
import ClientChangePhone from "./ClientChangePhone";

class ClientProposals extends Component {
    constructor(props) {
        super(props);

        this.state = {
            onlySmsCodeField: false
        }
    }

    componentDidMount() {
        const { page, pageSize } = this.props;
        this.fetchData(page, pageSize);
    }

    fetchData(page, pageSize) {
        this.props.getProposals(page, pageSize);
    }

    onChangePage = (page, pageSize) => {
        if (page !== this.props.page) {
            this.fetchData(page, pageSize);
        }
    }

    showSmsCodeField = (bool) => {
        this.setState({onlySmsCodeField: bool})
    }

    dateFormatter = row => {
        return new Date(row.created_at).toLocaleString('pl-PL')
    }

    statusFormatter = row => {
        return <ClientProposalStatus row={row} showSmsCodeField={this.showSmsCodeField}/>
    }

    columns = [
        {name: 'Identyfikator wniosku', dataField: 'proposal_id'},
        {name: 'Data utworzenia', dataField: 'created_at', formatter: this.dateFormatter},
        {name: 'Typ wniosku', dataField: 'type'},
        {name: 'Status', dataField: 'status', formatter: this.statusFormatter}
    ]

    render() {
        const { proposals, loading, page, pageSize } = this.props;

        if (this.state.onlySmsCodeField) {
            return (
                <ClientChangePhone verificationSent={true}/>
            )
        }

        return (
            <div className="row">
                <div className="col-lg-12">
                    <DataTable columns={this.columns}
                               rows={proposals.results}
                               recordsTotal={proposals.count}
                               page={page}
                               pageSize={pageSize}
                               paginated={true}
                               loading={loading}
                               onChangePage={this.onChangePage}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        proposals: state.clientInfo.proposals.data,
        loading: state.clientInfo.proposals.loading,
        page: state.clientInfo.proposals.page,
        pageSize: state.clientInfo.proposals.pageSize
    }
};

export default connect(
    mapStateToProps, client.actions
)(ClientProposals);