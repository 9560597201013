import React, {Component} from 'react'
import Nav from "react-bootstrap/Nav";
import {Link} from "react-router-dom";

export default class Menu extends Component {
    getActiveTab() {
        const url = window.location.href;

        if (url.includes('/profile')) {
            return '/profile'
        }

        if (url.includes('/invoices')) {
            return '/invoices'
        }

        if (url.includes('/payments')) {
            return '/payments'
        }

        if (url.includes('/proposals')) {
            return '/proposals'
        }

        return '/'
    }

    render() {
        return (
            <Nav fill variant="tabs" defaultActiveKey={this.getActiveTab()} style={{marginBottom: '30px'}}>
                <Nav.Item>
                    <Nav.Link eventKey="/" as={Link} to="/">Podsumowanie</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="/invoices" as={Link} to="/invoices/">Faktury</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="/payments" as={Link} to="/payments/">Płatności</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="/proposals" as={Link} to="/proposals/">Wnioski</Nav.Link>
                </Nav.Item>
            </Nav>
        )
    }
}