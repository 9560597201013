import React, {Component} from 'react';
import {history} from "../../store/store";

export default class ClientProposalStatus extends Component {
    PROPOSAL_CREATED = 1
    PROPOSAL_CONFIRMED = 2
    PROPOSAL_DONE = 3
    PROPOSAL_ERROR = 4
    PROPOSAL_CANCELLED = 5

    started(row) {
        return (
            <div>
                <span>Rozpoczęty</span>
                <div className="proposal-back" onClick={() => history.push('/proposal/' + row.proposal_hash)}>Wróć do wniosku</div>
            </div>
        )
    }

    text() {
        const { row } = this.props;

        switch(row.status) {
            case this.PROPOSAL_CREATED: {
                return row.valid ? this.started(row) : <span className="label label-danger">Upłynął termin weryfikacji</span>
            }

            case this.PROPOSAL_CONFIRMED: {
                return <span className="label label-warning">W trakcie realizacji</span>
            }

            case this.PROPOSAL_DONE: {
                return <span className="label label-success">Zrealizowany</span>
            }

            case this.PROPOSAL_ERROR: {
                return <span className="label label-danger">Wystąpił błąd</span>
            }

            case this.PROPOSAL_CANCELLED: {
                return <span className="label label-danger">Anulowany</span>
            }

            default: return '---'
        }
    }

    render() {
        return this.text()
    }
}