import React, {Component} from 'react';
import {connect} from "react-redux";
import * as auth from "../store/ducks/auth.duck";
import {Field, Form, Formik} from "formik";
import {FormikInputText} from "../forms/components/FormikInputText";
import {FormikInputPassword} from "../forms/components/FormikInputPassword";
import {Link} from "react-router-dom";
import {loginValidationSchema} from "../forms/values/login.values";


class Login extends Component {

    render() {
        const newAccountCode =
            this.props.location ? (this.props.location.newAccountCode ? this.props.location.newAccountCode : '') : '';

        return (
            <div className="container box-content rfc-login-container">
                <div className="row">
                    <p className="rfc-p">Logowanie</p>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{code: newAccountCode, password: ''}}
                    initialTouched={{code: true, password: true}}
                    validationSchema={loginValidationSchema}
                    onSubmit={(values, actions) => {
                        this.props.login(values.code, values.password);
                        actions.setSubmitting(false);
                    }}
                >{(props) => (
                    <Form>
                        <div className="row">
                            <div className="col-md-12">
                                <Field name="code" component={FormikInputText} label="Numer Klienta"/>
                            </div>
                            <div className="col-md-12">
                                <Field name="password" component={FormikInputPassword} label="Hasło"/>
                            </div>
                            <div className="col-md-6 rfc-login-form-links">
                                <Link to="/new-account/">
                                    <span className="rfc-p-forgot-password">Nie mam jeszcze konta</span>
                                </Link>
                            </div>
                            <div className="col-md-6 rfc-login-form-links">
                                <div style={{width: '100%'}}>
                                    <Link to="/reset-password/"
                                          className="reset-password-link"
                                          style={{display: 'block', float: 'right'}}>
                                        <span className="rfc-p-forgot-password">Resetuj hasło</span>
                                    </Link>
                                </div>
                            </div>
                            <button type="submit"
                                    className="btn btn-primary rfc-button rfc-button-login">Zaloguj się
                            </button>
                        </div>
                    </Form>
                )}
                </Formik>
            </div>
        )
    }
}


export default connect(
    null, auth.actions
)(Login);