const URL = process.env.REACT_APP_CLIENT_PANEL_BACKEND;

export const HEALTH_CHECK_URL = URL + 'api/auth/health-check/'

export const LOGIN_URL = URL + 'api/auth/login/'
export const CHANGE_PASSWORD_URL = URL + 'api/auth/change-password/'
export const RESET_PASSWORD_URL = URL + 'api/auth/request-recover-account/'
export const REFRESH_TOKEN_URL = URL + 'api/auth/token/refresh/'
export const REQUEST_NEW_ACCOUNT_URL = URL + 'api/auth/request-new-account/'
export const SAP_ACCESS_URL = URL + 'api/auth/obtain-sap-access/'
export const CLIENT_DECRYPT_URL = URL + 'api/auth/read-data/'

export const CLIENT_DOCUMENTS_URL = URL + 'api/documents/'
export const CLIENT_GENERATE_INVOICE_URL = URL + 'api/documents/generate-invoice/'

export const CLIENT_CREATE_URL = URL + 'api/customer-create/'
export const CLIENT_INFO_URL = URL + 'api/customer/'
export const CLIENT_INVOICES_URL = URL + 'api/invoices/'
export const CLIENT_PAYMENTS_URL = URL + 'api/payments/'
export const CLIENT_TRANSACTIONS_URL = URL + 'api/transactions/'
export const CLIENT_SETTINGS_URL = URL + 'api/client/settings/'

export const CLIENT_PROPOSALS_URL = URL + 'api/proposals/'
export const PROPOSAL_URL = URL + 'api/proposal/create/'
export const PROPOSAL_VERIFY_URL = URL + 'api/proposal/verify/'
export const CREATE_ACCOUNT_VERIFY_URL = URL + 'api/proposal/create-account/verify/'
export const PROPOSAL_GENERATE_ACCESS_CODE_URL = URL + 'api/auth/generate-proposal-access/'
export const PROPOSAL_ACCESS_URL = URL + 'api/auth/obtain-proposal-access/'
export const PROPOSAL_IS_VALID_URL = URL + 'api/proposal-valid/'
export const PROPOSAL_SIGN_URL = URL + 'api/proposal-sign/'

export const PAYNOW_TRANSACTION_URL = URL + 'api/payment-gate/paynow-transaction/'
export const PAYNOW_STATUS_URL = URL + 'api/payment-gate/paynow-status-check/'
export const PAYNOW_PAYMENT_COST_URL = URL + 'api/payment-gate/paynow-payment-cost/'
