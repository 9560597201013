import axios from "axios";
import {
    CHANGE_PASSWORD_URL,
    CLIENT_DECRYPT_URL,
    CREATE_ACCOUNT_VERIFY_URL,
    LOGIN_URL,
    PROPOSAL_ACCESS_URL,
    RESET_PASSWORD_URL
} from "../_config/config";
import {errorToast} from "../_utils/Toasts";

export function login(code, password) {
    return axios.post(LOGIN_URL, {code, password})
        .catch(err => {
            if (err.response) {
                if (err.response.status === 400) {
                    errorToast('Błędny identyfikator i/lub hasło')
                } else {
                    errorToast('Wystąpił błąd podczas logowania. Spróbuj ponownie później.')
                }
            } else {
                errorToast('Serwis tymczasowo niedostępny.')
            }
        })
}

export function changePassword(data) {
    return axios.post(CHANGE_PASSWORD_URL, {...data})
        .catch(err => {
            if (err.response.status === 400) {
                errorToast('Poprzednie hasło jest nieprawidłowe.')
            } else {
                errorToast('Wystąpił błąd. Spróbuj ponownie później.')
            }
        })
}

export function resetPassword(code) {
    return axios.post(RESET_PASSWORD_URL, {code: code})
}

export function sendAccessCode(hash, code) {
    return axios.post(PROPOSAL_ACCESS_URL, {proposal_hash: hash, sms_code: code})
        .catch(err => {
            if (err.response.status === 400) {
                errorToast('Błędny kod lub kod został już wykorzystany.')
            } else {
                errorToast('Wystąpił błąd. Spróbuj ponownie później.')
            }
        })
}

export function decryptHash(hash) {
    return axios.post(CLIENT_DECRYPT_URL, {hash: hash})
}

export function createAccountProposalSubmit(values) {
    return axios.post(CREATE_ACCOUNT_VERIFY_URL, values)
}