import React, {Component} from 'react';

export default class AttachInvoice extends Component {
    render() {
        const {attachInvoice} = this.props;

        return (
            <>
                <p style={{fontSize: '11px', marginTop: '15px'}}>
                    Załączanie faktury do wiadomości: {attachInvoice ? <b style={{color: 'green'}}>TAK</b> : <b style={{color: 'red'}}>NIE</b>}
                </p>
            </>
        )
    }

}