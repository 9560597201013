import React, {Component} from 'react';
import {
    Checkbox,
    FormControlLabel
} from "@material-ui/core";
import qs from "qs";
import axios from "axios";
import {Field} from "formik";
import {debounce} from "../../_utils/utils";
import {FormikAutocomplete} from "../../forms/components/FormikAutocomplete";
import {TextField} from "formik-material-ui";

const URL = "http://localhost:8001/api/";

export default class AddressForm extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            teryt: {
                city: Array(0),
                street: Array(0)
            },
            search: {
                city: null
            }
        }
        this.handleInputChangeTerytCity = debounce(300, this.handleInputChangeTerytCity)
        this.handleInputChangeTerytStreet = debounce(300, this.handleInputChangeTerytStreet)
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchTeryt = (search) => {
        axios.get(URL + `hp/?${qs.stringify(search)}`)
            .then(json => {
                if (this._isMounted) {
                    this.setState({teryt: json.data.data})
                }
            })
            .catch(err => console.log(err));
    };

    handleChangeTerytCity = event => {
        let value = event ? event.target.value : '';
        if (!value) return;
        this.handleInputChangeTerytCity(value);
    }

    handleInputChangeTerytCity = (value) => {
        if (value.length > 2) {
            let searchValue = value;
            let i = value.indexOf(" woj.");

            if (i > 0) {
                searchValue = value.substr(0, i);
            }

            this.fetchTeryt({
                city: searchValue
            })
        }

        return value;
    }

    handleChangeTerytStreet = event => {
        let value = event ? event.target.value : '';
        if (!value) return;
        this.handleInputChangeTerytStreet(value);
    }

    handleInputChangeTerytStreet = (value) => {
        if (value.length > 2 && this.props.formik.values[`${this.props.form}`].teryt.city) {
            this.fetchTeryt({
                sym: this.props.formik.values[`${this.props.form}`].teryt.city.sym,
                street: value
            });
        }
        return value;
    };

    cityChanged(form, value) {
        const val = this.props.formik.values[`${form}`]
        if (value !== val.teryt.city.name + ' ' + val.teryt.city.terc) {
            val.teryt.street = {
                sym: null,
                name: '',
                sym_ul: null
            }
            val.build_number = ''
            val.home_number = ''
            val.postal_code = ''
        }
    }

    streetChanged(form, value) {
        const val = this.props.formik.values[`${form}`]
        if (value !== val.teryt.street.name) {
            val.build_number = ''
            val.home_number = ''
            val.postal_code = ''
        }
    }

    hasHomeNumberChanged(event, formik, form) {
        formik.setFieldValue(`${form}.has_home_number`, event.target.checked);
        if (!event.target.checked) {
            formik.setFieldValue(`${form}.home_number`, '')
        }
    }

    render() {
        const {form, formik, disabled} = this.props;

        return (
            <>
                <div className="col-md-12 col-xs-12">
                    <Field
                        type="select"
                        name={`${form}.teryt.city`}
                        textFieldProps={{
                            label: 'Miasto'
                        }}
                        component={FormikAutocomplete}
                        options={this.state.teryt.city}
                        onInputChange={(event, value) => {
                            if (formik.values[`${form}`].teryt.city) {
                                this.cityChanged(form, value, formik.values);
                            }
                            this.handleChangeTerytCity(event);
                        }}
                        getOptionLabel={(option) => option.name ? option.name + ' ' + option.terc : ''}
                        getOptionSelected={(option, value) => option.name === value.name}
                        disabled={disabled}
                    />
                </div>
                <div className="col-md-12 col-xs-12">
                    <Field
                        type="select"
                        name={`${form}.teryt.street`}
                        textFieldProps={{
                            label: 'Ulica'
                        }}
                        component={FormikAutocomplete}
                        options={this.state.teryt.street}
                        onInputChange={(event, value) => {
                            if (formik.values[`${form}`].teryt.street) {
                                this.streetChanged(form, value, formik.values);
                            }
                            this.handleChangeTerytStreet(event);
                        }}
                        getOptionLabel={(option) => option.name ? option.name : ''}
                        getOptionSelected={(option, value) => option.name === value.name}
                        disabled={disabled}
                    />
                </div>
                <div className="col-md-4 col-xs-6">
                    <Field
                        name={`${form}.postal_code`}
                        label='Kod pocztowy'
                        component={TextField}
                        disabled={disabled}
                        style={{width: '100%'}}
                    />
                </div>
                <div className="col-md-4 col-xs-6">
                    <Field
                        name={`${form}.build_number`}
                        label='Nr budynku'
                        component={TextField}
                        disabled={disabled}
                        style={{width: '100%'}}
                    />
                </div>
                <div className="col-md-4 col-xs-6">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values[`${form}`].has_home_number}
                                onChange={event => this.hasHomeNumberChanged(event, formik, form)}
                                disabled={disabled}
                                color="primary"/>
                        }
                        label="Ma nr mieszkania"
                    />
                </div>
                <div className="col-md-6 col-xs-6">
                    <Field
                        name={`${form}.home_number`}
                        label='Nr mieszkania'
                        component={TextField}
                        hidden={!formik.values[`${form}`].has_home_number}
                        disabled={disabled || !formik.values[`${form}`].has_home_number}
                        style={{width: '100%'}}
                    />
                </div>
            </>
        )
    }
}
