import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import {changePassword, login, sendAccessCode} from "../../crud/auth.crud";
import {sapAccess} from "../../crud/client.crud";
import {successToast} from "../../_utils/Toasts";
import {push} from "connected-react-router";

export const actionTypes = {
    SapAccess: "[SAP] Access",
    Login: "[Login] Action",
    Logout: "[Logout] Action",
    EnablePage: "[PANEL] Enable",
    DisablePage: "[PANEL] Disable",
    ChangePassword: "[Password] change",
    // ResetPassword: "[Password] reset",
    PasswordChanged: "[Password] changed",
    UserLoaded: "[Load User] Auth API",
    RefreshToken: "[JWT] Refresh Token",
    ProposalAccess: "[PROPOSAL] Access"
};

const initialAuthState = {
    customer_service_id: undefined,
    code: undefined,
    phone: undefined,
    has_changed_password: false,
    access: undefined,
    refresh: undefined,
    maintenance: false
};

export const reducer = persistReducer(
    {storage, key: "panel-auth"},
    (state = initialAuthState, action) => {
        switch (action.type) {
            case actionTypes.UserLoaded: {
                return {...action.payload};
            }

            case actionTypes.Logout: {
                return initialAuthState;
            }

            case actionTypes.ResetPassword: {
                return {...state, has_changed_password: false}
            }

            case actionTypes.EnablePage: {
                return {...state, maintenance: false}
            }

            case actionTypes.DisablePage: {
                return {...initialAuthState, maintenance: true}
            }

            case actionTypes.PasswordChanged: {
                return { ...state, has_changed_password: true }
            }

            case actionTypes.RefreshToken: {
                const {access} = action.payload;
                return {...state, access: access};
            }

            default:
                return state;
        }
    }
);

export const actions = {
    access: (hash) => ({type: actionTypes.SapAccess, payload: {hash}}),
    login: (code, password) => ({type: actionTypes.Login, payload: {code, password}}),
    fulfillUser: (code, phone_number, has_changed_password, access, refresh) => ({type: actionTypes.UserLoaded, payload: {code, phone_number, has_changed_password, access, refresh}}),
    changePassword: (values) => ({type: actionTypes.ChangePassword, payload: {...values}}),
    // resetPassword: (code) => ({type: actionTypes.ResetPassword, payload: {code}}),
    passwordChanged: () => ({ type: actionTypes.PasswordChanged }),
    logout: () => ({type: actionTypes.Logout}),
    enablePage: () => ({type: actionTypes.EnablePage}),
    proposalAccess: (hash, code) => ({type: actionTypes.ProposalAccess, payload: {hash, code}})
};

export function* saga() {
    yield takeLatest(actionTypes.SapAccess, function* sapAccessSaga(data) {
        const {hash} = data.payload;
        const resp = yield sapAccess(hash);
        const {data: { data: user }} = resp;
        yield put(actions.fulfillUser(user.code, user.phone_number, user.has_changed_password, user.access, user.refresh))
        yield put(push('/'))
    });

    yield takeLatest(actionTypes.Login, function* loginSaga(data) {
        const {code, password} = data.payload;
        const resp = yield login(code, password);
        if (resp) {
            const { data: user } = resp
            successToast('Zalogowano.')
            yield put(actions.fulfillUser(user.code, user.phone_number, user.has_changed_password, user.access, user.refresh));
        }
    });

    yield takeLatest(actionTypes.ChangePassword, function* changePasswordSaga(data) {
        const payload = data.payload;
        const resp = yield changePassword(payload);
        if (resp && resp.status === 200) {
            successToast('Twoje hasło zostało zmienione.')
            yield put(actions.passwordChanged())
            yield put(push('/'))
        }
    });

    // yield takeLatest(actionTypes.ResetPassword, function* resetPasswordSaga(data) {
    //     const {code} = data.payload;
    //     const resp = yield resetPassword(code);
    //     if (resp.status === 200) successToast('Nowe hasło zostało wysłane na podany w umowie numer telefonu.')
    // });

    yield takeLatest(actionTypes.ProposalAccess, function* proposalAccessSaga(data) {
        const { hash, code } = data.payload;
        const resp = yield sendAccessCode(hash, code);
        if (resp && resp.status === 200) {
            const { data: { data } } = resp;
            yield put(actions.fulfillUser(data.code, data.phone_number, data.has_changed_password, data.access, data.refresh))
            successToast('Uzyskano dostęp do wniosku.')
        }
    })
}
