import React, {Component} from 'react';

export default class ErrorIcon extends Component {
    render() {
        return (
            <div className="f-modal-icon f-modal-error animate">
                <span className="f-modal-x-mark">
                    <span className="f-modal-line f-modal-left animateXLeft"/>
                    <span className="f-modal-line f-modal-right animateXRight"/>
                </span>
                <div className="f-modal-placeholder"/>
                <div className="f-modal-fix"/>
            </div>
        )
    }
}