import {Component} from "react";

export class Phone extends Component {

    render() {

        if (!this.props.phone) {
            return ''
        }

        let chuncks = this.props.phone.match(/.{1,3}/g);
        return (
            chuncks.join("-")
        );
    }
}