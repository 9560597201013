import {object, string} from "yup";

export const changePasswordInitialValues = {
    old_password: '',
    new_password: '',
    confirm_new_password: ''
}

export const changePasswordValidationSchema = object().shape({
    old_password: string().required('Wprowadź poprzednie hasło'),
    new_password: string().required('Wprowadź nowe hasło').matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Hasło musi składać się przynajmniej z 8 znaków, musi zawierać przynajmniej jedną wielką i małą literę, jedną cyfrę i jeden znak specjalny z puli: @$!%*#?&"
    ),
    confirm_new_password: string().required('Potwierdź nowe hasło')
        .test('passwords-match', 'Hasła muszą byc takie same', function (value) {
            return this.parent.new_password === value;
        }),
})