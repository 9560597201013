import React, {Component} from 'react';
import {connect} from "react-redux";
import * as client from "../../store/ducks/client.duck";
import {downloadFile} from "../../_utils/utils";
import DataTable from "../_common/DataTable";
import ClientInvoicesPay from "./ClientInvoicesPay";
import SpinnerIcon from "../_common/statusIcons/SpinnerIcon";

import axios from "axios";
import {errorToast} from "../../_utils/Toasts";
import {CLIENT_GENERATE_INVOICE_URL} from "../../_config/config";

class ClientInvoices extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showPaymentWindow: false,
            docs: [],
            showAll: false
        }
    }

    invoicesToPay() {
        return this.props.invoices.data ? this.props.invoices.data.filter(invoice => (invoice.do_zaplaty > 0 && invoice.nr_pelny.startsWith('FS'))) : []
    }

    getTotalSum() {
        const invoices = this.invoicesToPay();
        if (invoices) {
            return invoices.reduce((accumulator, value) => accumulator + parseInt(Math.ceil(value.do_zaplaty * 100)), 0)
        }
        return 0
    }

    checkPreviousPayment() {
        const {paymentId, paymentStatus} = this.props;

        if (paymentStatus === 'NEW' || paymentStatus === 'PENDING') {
            this.props.checkPaymentStatus(paymentId);
        }
    }

    componentDidMount() {
        const {page, pageSize} = this.props;
        this.fetchData(page, pageSize);
        this.fetchDocuments();
        this.checkPreviousPayment();
    }

    fetchDocuments() {
        this.props.getDocuments('INVOICE');
    }

    fetchData(page, pageSize, filters = {}) {
        this.props.getInvoices(page, pageSize, filters);
    }

    // checkboxFormatter = (row) => {
    //     if (row.do_zaplaty > 0 && row.nr_pelny.startsWith('FS')) {
    //         return (
    //             <label>
    //                 <input type="checkbox"
    //                        className="filled-in"
    //                        // disabled={this.props.paymentStatus === 'NEW' || this.props.paymentStatus === 'PENDING'}
    //                        disabled={true}
    //                        checked={true}
    //                        hidden
    //                        // onChange={(event) => {
    //                        //     if (!event.target.checked) {
    //                        //         let index = this.state.selectedInvoices.indexOf(row);
    //                        //         if (index !== -1) this.state.selectedInvoices.splice(index, 1);
    //                        //     } else {
    //                        //         this.state.selectedInvoices.push(row);
    //                        //     }
    //                        //     this.setState({payButtonDisabled: !this.state.selectedInvoices.length})
    //                 />
    //                 <span>&nbsp;</span>
    //             </label>
    //         )
    //     }
    // }

    requestInvoiceDownload = (row, sub_type) => {
        const data = {
            sub_type: sub_type,
            invoice_id: row.sfera_id,
            name: row.nr_pelny
        }
        axios.post(CLIENT_GENERATE_INVOICE_URL, data)
            .then(resp => {
                this.fetchDocuments();
            })
            .catch(err => errorToast('Coś poszło nie tak.'))
    }

    renderLabel = (row) => {
        if (row.do_zaplaty === 0 || row.nr_pelny.startsWith('KFS')) {
            return <span className="label label-success"><b>Opłacona</b></span>
        }

        if (row.do_zaplaty > 0) {
            return <span className="label label-danger"><b>{row.do_zaplaty.toFixed(2)} PLN</b></span>
        }
    }

    generateInvoiceFormatter = (row) => {

        if (row.nr_pelny.includes('KOR')) {
            return <div/>
        }

        let sub_type;
        if (row.nr_pelny.includes('KFS')) {
            sub_type = 561
        } else {
            sub_type = 1000001
        }

        if (!this.props.documents) {
            return null
        }

        let filteredDocs = this.props.documents.filter(document => document.object_id === row.sfera_id)
        if (filteredDocs.length) {
            let document = filteredDocs[0]
            if (document.status === 'DONE') {
                return (
                    <i className="fa fa-download" onClick={() => {
                        downloadFile(document);
                    }}/>
                )
            } else {
                return (
                    <div>
                        <div style={{width: '1rem', height: '1rem'}} className="spinner-border text-danger"/>
                    </div>
                )
            }
        } else {
            return (
                <i style={{color: "#21252955"}} className="fa fa-download" onClick={() => {
                    this.requestInvoiceDownload(row, sub_type);
                }}/>
            )
        }
    }

    shouldDisablePayButton = () => {
        const { clientEmail, loading } = this.props;
        return loading || !clientEmail || !this.invoicesToPay().length || this.getTotalSum() < 100
    }

    onChangePage = (page, pageSize) => {
        if (page !== this.props.page) {
            if (this.state.showAll) {
                this.fetchData(page, pageSize, {'document_type': 'FS,KFS,PI'});
            } else {
                this.fetchData(page, pageSize);
            }
        }
    }

    changeShowAll = (event) => {
        this.setState({showAll: event.target.checked});
        if (event.target.checked) {
            this.fetchData(1, 5, {'document_type': 'FS,KFS,PI'});
        } else {
            this.fetchData(1, 5);
        }
    }

    nettoFormatter = row => {
        return row.netto.toFixed(2);
    }

    bruttoFormatter = row => {
        return row.brutto.toFixed(2);
    }

    columns = [
        // {dataField: 'checkbox', name: '', formatter: this.checkboxFormatter, classes: 'centered'},
        {dataField: 'do_zaplaty', name: 'Do zapłaty', formatter: this.renderLabel},
        {dataField: 'nr_pelny', name: 'Numer'},
        {dataField: 'netto', name: 'netto', formatter: this.nettoFormatter},
        {dataField: 'brutto', name: 'brutto', formatter: this.bruttoFormatter},
        {dataField: 'data_wystawienia', name: 'Data wystawienia'},
        {dataField: 'termin_platnosci', name: 'Termin płatności'},
        {dataField: 'PDF', name: 'PDF', classes: 'td-pdf', formatter: this.generateInvoiceFormatter},
    ]

    render() {
        const {clientEmail, invoices, loading, page, pageSize, paymentStatus, paymentUrl} = this.props;

        let pendingPaymentContent = null;

        if (paymentStatus === 'NEW' || paymentStatus === 'PENDING') {
            pendingPaymentContent = (
                <div style={{textAlign: 'center'}}>
                    <p>Poprzednia płatność jeszcze się nie zakończyła</p>
                    <SpinnerIcon/>
                    <div style={{marginTop: '25px'}}>
                        <a href={paymentUrl}><b>Wróć do płatności</b></a>
                    </div>
                </div>
            )
        }

        if (this.state.showPaymentWindow) {
            return <ClientInvoicesPay invoices={this.invoicesToPay()}/>
        }

        if (!this.state.showPaymentWindow) {
            return (
                <div className="row">
                    <div className="col-lg-12">
                        {pendingPaymentContent}
                        <div className="row">
                            {!clientEmail && (
                                <div style={{width: '100%'}}>
                                    <span style={{fontSize: '11px !important'}}>Wymagane uzupełnienie adresu e-mail</span>
                                </div>
                            )}
                            {/*<div className="div-client-invoices">*/}
                            {/*    <button className="btn btn-primary rfc-button"*/}
                            {/*            disabled={this.shouldDisablePayButton()}*/}
                            {/*            onClick={() => this.setState({showPaymentWindow: true})}>*/}
                            {/*        Opłać faktury*/}
                            {/*    </button>*/}
                            {/*</div>*/}
                            {/*<div style={{width: '100%'}}>*/}
                            {/*    <div style={{float: "right", paddingBottom: '5px'}}>*/}
                            {/*        <label>*/}
                            {/*            <input type="checkbox"*/}
                            {/*                   className="filled-in"*/}
                            {/*                   id="showAll"*/}
                            {/*                   onChange={this.changeShowAll}*/}
                            {/*                   checked={this.state.showAll}/>*/}
                            {/*            <span className="showAll">Pokaż wszystkie faktury</span>*/}
                            {/*        </label>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <DataTable columns={this.columns}
                                   rows={invoices.data}
                                   recordsTotal={invoices.recordsTotal}
                                   page={page}
                                   pageSize={pageSize}
                                   onChangePage={this.onChangePage}
                                   loading={loading}
                                   paginated={true}/>
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        clientEmail: state.clientInfo.client.data ? state.clientInfo.client.data.customer_1.email : '',
        invoices: state.clientInfo.invoices.data,
        loading: state.clientInfo.invoices.loading,
        page: state.clientInfo.invoices.page,
        pageSize: state.clientInfo.invoices.pageSize,
        paymentId: state.clientInfo.pendingPayment.paymentId,
        paymentStatus: state.clientInfo.pendingPayment.status,
        paymentUrl: state.clientInfo.pendingPayment.url,
        documents: state.clientInfo.documents.data
    }
};

export default connect(
    mapStateToProps, client.actions
)(ClientInvoices);