import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from "react-redux";

const PrivateRoute = ({isLoggedIn, hasChangedPassword, ...props}) => {
    const hasChangedPasswordChecker = () => {
        if (!hasChangedPassword) {
            if (props.path !== '/change-password/' && props.path !== '/logout/') {
                return <Redirect to="/change-password/"/>
            } else {
                return <Route {...props}/>
            }
        }
        return <Route {...props}/>
    }

    return isLoggedIn ? hasChangedPasswordChecker() : <Redirect to="/login/"/>
}


const mapStateToProps = state => {
    return {
        isLoggedIn: !!state.auth.code,
        hasChangedPassword: state.auth.has_changed_password
    }
}

export default connect(mapStateToProps)(PrivateRoute)