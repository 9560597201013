import React, {Component} from 'react';
import Header from "./Header";
import {Redirect, Route, Switch} from "react-router-dom";
import Login from "./Login";
import Footer from "./Footer";
import ResetPassword from "./ResetPassword";
import NewAccount from "./NewAccount";
import ClientInfo from "./client/ClientInfo";
import Menu from "./Menu";
import Logout from "./Logout";
import {connect} from "react-redux";
import PrivateRoute from "../_utils/PrivateRoute";
import ClientProfile from "./client/ClientProfile";
import ClientInvoices from "./client/ClientInvoices";
import ClientPayments from "./client/ClientPayments";
import ClientInvoicesPay from "./client/ClientInvoicesPay";
import ClientPaymentStatus from "./client/ClientPaymentStatus";
import ClientInfoSAPAccess from "./client/ClientInfoSAPAccess";
import ClientVerificationEmailDone from "./client/ClientVerificationEmailDone";
import ClientProposals from "./client/ClientProposals";
import ClientChangeData from "./client/ClientChangeData";
import Maintenance from "./Maintenance";
import ClientChangePassword from "./client/ClientChangePassword";
import CreateAccount from "./CreateAccount";
import ClientProposal from "./client/ClientProposal";
import GainAccessRoute from "../_utils/GainAccessRoute";


class Main extends Component {

    render() {
        return (
            <>
                <Header/>
                <main className="main-content">
                    <div className="container">
                        <p className="rfc-p-header">Strefa Klienta</p>
                        {(this.props.code && this.props.hasChangedPassword) && (
                            <Menu/>
                        )}
                        <Switch>
                            <Route path="/maintenance/" component={Maintenance}/>
                            {this.props.maintenance && (
                                <Redirect to="/maintenance/"/>
                            )}
                            {!this.props.maintenance && (
                                <Redirect from="/maintenance/" to="/"/>
                            )}
                            {(this.props.code && this.props.hasChangedPassword) && (
                                <Redirect from="/login/" to="/"/>
                            )}
                            {(this.props.code && !this.props.hasChangedPassword) && (
                                <Redirect from="/login/" to="/change-password/"/>
                            )}
                            <PrivateRoute exact path="/" component={ClientInfo}/>
                            <PrivateRoute path="/logout/" component={Logout}/>
                            <PrivateRoute path="/change/" component={ClientChangeData}/>
                            <PrivateRoute path="/change-password/" component={ClientChangePassword}/>
                            <PrivateRoute path="/profile/" component={ClientProfile}/>
                            <PrivateRoute path="/invoices/pay/status/" component={ClientPaymentStatus}/>
                            <PrivateRoute path="/invoices/pay/" component={ClientInvoicesPay}/>
                            <PrivateRoute path="/invoices/" component={ClientInvoices}/>
                            <PrivateRoute path="/payments/" component={ClientPayments}/>
                            <PrivateRoute path="/proposals/" component={ClientProposals}/>
                            <GainAccessRoute path="/proposal/:proposalHash" component={ClientProposal}/>
                            <Route path="/verify/:code" component={ClientVerificationEmailDone}/>
                            <Route path="/sap/:code" component={ClientInfoSAPAccess}/>
                            <Route path="/login/" component={Login}/>
                            <Route path="/reset-password/" component={ResetPassword}/>
                            <Route path="/new-account/" component={NewAccount}/>
                            <Route path="/create-account/:hash?" component={CreateAccount}/>
                        </Switch>
                    </div>
                </main>
                <Footer/>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        code: state.auth.code,
        hasChangedPassword: state.auth.has_changed_password,
        maintenance: state.auth.maintenance
    }
};

export default connect(
    mapStateToProps, null
)(Main);