import React, {Component} from 'react';
import {connect} from "react-redux";
import * as client from "../../store/ducks/client.duck";
import * as auth from "../../store/ducks/auth.duck";
import {bindActionCreators} from "redux";
import ClientInfoRender from "./ClientInfoRender";

class ClientInfo extends Component {
    fetchData() {
        const {code} = this.props;

        if (code) {
            this.props.getClient(code);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.code !== prevProps.code) {
            const {code} = this.props;
            this.props.getClient(code);
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        return <ClientInfoRender/>
    }
}

const mapStateToProps = state => {
    return {
        code: state.auth.code,
        has_changed_password: state.auth.has_changed_password,
        client: state.clientInfo.client.data,
        loading: state.clientInfo.client.loading
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...client.actions,
            access: auth.actions.access
        }, dispatch
    );
}


export default connect(
    mapStateToProps, mapDispatchToProps
)(ClientInfo);