import React, {Component} from "react";
import {Field, Form, Formik} from "formik";
import {FormikInputText} from "../../forms/components/FormikInputText";
import axios from "axios";
import {PROPOSAL_URL} from "../../_config/config";
import {errorToast, successToast} from "../../_utils/Toasts";
import {changePhoneValationSchema} from "../../forms/values/changephone.values";
import {history} from "../../store/store";

export default class ClientChangePhone extends Component {
    patchPhone = (phone) => {
        axios.post(PROPOSAL_URL, {tpe: 'CHANGE_PHONE', json: {phone: phone}})
            .then(res => {
                successToast('Wniosek o zmianę nr telefonu został złożony poprawnie.');
                history.push('/proposal/' + res.data.proposal_hash)
            })
            .catch(err => errorToast('Coś poszło nie tak.'))
    }

    render() {
        return (
            <div className="container box-content">
                <div className="row">
                    <p className="rfc-p">Zmiana nr telefonu</p>
                </div>

                <Formik
                    initialValues={{phone: ''}}
                    initialTouched={{phone: true}}
                    validationSchema={changePhoneValationSchema}
                    onSubmit={(values) => {
                        this.patchPhone(values.phone)
                    }}
                >
                    {(formik) => (
                        <Form autoComplete="off">
                            <div className="row">
                                <div className="col-md-12">
                                    <Field name="phone" component={FormikInputText} label="Nowy nr telefonu"/>
                                </div>
                                <button type="submit"
                                        disabled={formik.isSubmitting}
                                        className="btn btn-primary rfc-button rfc-button-login">
                                    Zmień nr telefonu
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}