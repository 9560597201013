import React, {Component} from 'react';
import {connect} from "react-redux";
import * as auth from "../store/ducks/auth.duck";
import {Redirect} from "react-router-dom";

class Logout extends Component {
    componentDidMount() {
        this.props.logout();
    }

    render() {
        return <Redirect to="/login"/>
    }
}

export default connect(
    null, auth.actions
)(Logout);