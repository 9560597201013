import React from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";
import './index.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'materialize-css/dist/css/materialize.min.css';
import 'font-awesome/css/font-awesome.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import {setupAxios} from "./app/_utils/utils";
import store from "./app/store/store";

setupAxios(axios, store);

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);

Sentry.init({
    dsn: "https://dcc11a9d41824d41b9c0550bd2dc430a@sentry.net2b.pl/17",
    environment: `${process.env.REACT_APP_SENTRY_ENVIRONMENT}`
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
