import React, {useState} from 'react';
import {createProposal, signProposal} from "../../../crud/client.crud";
import {Table} from "react-bootstrap";
import {history} from "../../../store/store";
import LabelledText from "../../_common/LabelledText";
import SignProposal from "../../SignProposal";
import {errorToast, successToast} from "../../../_utils/Toasts";
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import {connect} from "react-redux";

function SignAgreement(props) {
    const [changeData, setChangeData] = useState(false);
    const [lastStep, setLastStep] = useState(false);
    const [showHello, setShowHello] = useState(false);

    const submit = (values) => {
        signProposal(values)
            .then(_ => {
                successToast('Umowa została zawarta.')
                setShowHello(true);
            })
            .catch(err => console.log(err))
    }

    if (showHello) {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Dziękujemy za zawarcie Umowy :)</p>
                        <p style={{textAlign: 'justify'}}>
                            Wysłaliśmy ją na podany przez Ciebie adres e-mail.
                            Po zamknięciu tego okna zostaniesz przekierowany(-a) do swojego profilu w Strefie Klienta RFC.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <button
                        type="button"
                        onClick={() => {
                            if (!props.hasChangedPassword) {
                                history.push('/reset-password/', {signed: true})
                            } else {
                                history.push('/')
                            }
                        }}
                        className="btn btn-primary rfc-button rfc-button-login">
                        Zamknij okno
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div className="row">
            {!changeData && (
                <div className="col-md-12">
                    <p style={{fontWeight: '600'}}>
                        {lastStep ? 'Krok 3: Zawarcie Umowy': 'Krok 2: Podsumowanie i zatwierdzenie wniosku'}
                    </p>
                    <p style={{textAlign: 'justify'}}>
                        Zanim {lastStep ? 'zatwierdzisz wniosek' : 'zamówisz usługę'}, zweryfikuj poniższe dane.
                        W przypadku konieczności poprawienia
                        {props.proposal.json.type === 0 ? (' nazwiska lub numeru PESEL, ') : (' nazwy firmy lub numeru NIP ')}
                        <span style={{color: '#d9000d', cursor: 'pointer'}}
                                      onClick={() => setChangeData(true)}>kliknij tutaj</span>.
                        Jeżeli inne dane wymagają poprawy, skontaktuj się z Biurem Obsługi Klienta
                        (internet@rfc.pl / +48 52 55 11 333).
                    </p>
                </div>
            )}
            <div className="col-md-12">
                <p style={{fontWeight: '600', fontSize: '17px', borderBottom: '1px solid #d9000d', color: '#d9000d'}}>
                    Podsumowanie - Dane podstawowe
                </p>
            </div>
            {changeData ? (
                <DisplayForm proposal={props.proposal}/>
            ) : (
                <DisplayData data={props.proposal.json}/>
            )}
            <div className="col-md-6 col-xs-12">
                <LabelledText text={props.proposal.json.email} label={'Adres e-mail'}/>
            </div>
            <div className="col-md-12 col-xs-12">
                <LabelledText text={props.proposal.json.base_address.location} label={'Adres podstawowy'}/>
            </div>
            <div className="col-md-12">
                <LabelledText text={props.proposal.json.correspondence.location} label={'Adres korespondencji'}/>
            </div>
            <div className="col-md-12">
                <LabelledText text={props.proposal.json.house_point.location} label={'Adres instalacji'}/>
            </div>
            <div className="col-md-12">
                <p style={{fontWeight: '600', fontSize: '17px', color: '#d9000d',
                    borderBottom: '1px solid #d9000d', marginTop: '15px'}}>
                    Podsumowanie - Wybrane usługi
                </p>
            </div>
            <div className="col-md-12">
                <AgreementSummaryServices context={props.proposal.json.services_details}/>
            </div>
            {!changeData ? (
                <div className="col-md-12">
                    <SignProposal header={'Zawarcie Umowy'}
                                  buttonConfirmText={'Zatwierdź wniosek'}
                                  buttonSaveText={'Zawieram Umowę'}
                                  buttonRejectText={'Anuluj'}
                                  description={'Na podany przez Ciebie numer telefonu została wysłana wiadomość' +
                                  ' z kodem SMS-2. Aby zawrzeć Umowę, wprowadź ten kod poniżej i kliknij przycisk' +
                                  ' "Zawieram Umowę"'}
                                  proposal={props.proposal}
                                  onClickExtra={() => setLastStep(true)}
                                  submit={submit}
                                  phone={props.proposal.json.phone}/>
                </div>
            ) : (
                <>
                    <div className="col-md-6 col-xs-6">
                        <button
                            type="submit"
                            form="data-form"
                            className="btn btn-primary rfc-button rfc-button-short">
                            Zapisz
                        </button>
                    </div>
                    <div className="col-md-6 col-xs-6">
                        <button type="button"
                                className="btn btn-primary rfc-button rfc-button-small"
                                style={{backgroundColor: '#fff', color: 'black', margin: '10px 5px'}}
                                onClick={() => setChangeData(false)}>
                            Anuluj
                        </button>
                    </div>
                </>
            )}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        hasChangedPassword: state.auth.has_changed_password
    }
}

export default connect(mapStateToProps)(SignAgreement);

function DisplayForm(props) {
    const createChangeDataProposal = values => {
        createProposal('MODIFY_AGREEMENT_DATA', mapValues(values))
            .then(res => history.push('/proposal/' + res.data.proposal_hash))
            .catch(_ => errorToast('Wystąpił problem z utworzeniem wniosku.'))
    }

    const mapValues = values => {
        return {
            ...values,
            proposal_to_cancel: props.proposal.proposal_hash
        }
    }

    return (
        <Formik
            initialValues={{
                last_name: props.proposal.json.last_name,
                pesel: props.proposal.json.pesel,
                name: props.proposal.json.name,
                nip: props.proposal.json.nip
            }}
            onSubmit={(values) => {
                createChangeDataProposal(values);
            }}
        >
            {(formik) => (
                <Form id="data-form">
                    {props.proposal.json.type === 0 ? (
                        <div className="row">
                            <div className="col-md-6 col-xs-12">
                                <LabelledText text={props.proposal.json.first_name} label={'Imię'}/>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <Field
                                    name="last_name"
                                    component={TextField}
                                    style={{width: '100%'}}
                                    label="Nazwisko"/>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <LabelledText text={props.proposal.json.phone} label={'Numer telefonu'}/>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <Field
                                    name="pesel"
                                    component={TextField}
                                    style={{width: '100%'}}
                                    label="Pesel"/>
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-md-6 col-xs-12">
                                <Field
                                    name="name"
                                    component={TextField}
                                    style={{width: '100%'}}
                                    label="Nazwa firmy"
                                />
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <Field
                                    name="nip"
                                    component={TextField}
                                    style={{width: '100%'}}
                                    label="NIP"
                                />
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <LabelledText text={props.proposal.json.phone} label={'Numer telefonu'}/>
                            </div>
                        </div>
                    )}

                </Form>
            )}
        </Formik>
    )
}

function DisplayData(props) {
    if (props.data.type === 0) {
        return (
            <>
                <div className="col-md-6 col-xs-12">
                    <LabelledText text={props.data.first_name} label={'Imię'}/>
                </div>
                <div className="col-md-6 col-xs-12">
                    <LabelledText text={props.data.last_name} label={'Nazwisko'}/>
                </div>
                <div className="col-md-6 col-xs-12">
                    <LabelledText text={props.data.phone} label={'Numer telefonu'}/>
                </div>
                <div className="col-md-6 col-xs-12">
                    <LabelledText text={props.data.pesel} label={'Pesel'}/>
                </div>
            </>
        )
    }
    return (
        <>
            <div className="col-md-6 col-xs-12">
                <LabelledText text={props.data.name} label={'Nazwa firmy'}/>
            </div>
            <div className="col-md-6 col-xs-12">
                <LabelledText text={props.data.nip} label={'NIP'}/>
            </div>
            <div className="col-md-6 col-xs-12">
                <LabelledText text={props.data.phone} label={'Numer telefonu'}/>
            </div>
        </>
    )
}

function AgreementSummaryServices(props) {
    const getContext = () => {
        const { context } = props;
        let subscriptionServices = []
        let activationServices = []
        for (let [key, value] of Object.entries(context)) {
            if (key === '1') {
                for (let [k, s] of Object.entries(value.services)) {
                    s.variants.forEach(v => {
                        subscriptionServices.push(
                            <tr>
                                <td data-label="Usługi Abonamentowe">{v.name}</td>
                                <td data-label="Okres zobowiązania">{s.col_values[v.id][0]}</td>
                                {/*<td data-label="Opłata abonamentowa bez promocji">{s.col_values[v.id][1]}</td>*/}
                                <td data-label="Opłaty abonamentowe w promocji">{s.col_values[v.id][2]}</td>
                                {/*<td data-label="Miesięczna wartość ulgi">{s.col_values[v.id][3]}</td>*/}
                                {/*<td data-label="Łączna wartość ulgi w okresie zobowiązania">{s.col_values[v.id][4]}</td>*/}
                            </tr>
                        )
                    })
                }
            }

            if (key === '2') {
                for (let [k, s] of Object.entries(value.services)) {
                    s.variants.forEach(v => {
                        activationServices.push(
                            <tr>
                                <td data-label="Usługi aktywacyjne/instalacyjne oraz pozostałe">{v.name}</td>
                                {/*<td data-label="Opłata bez promocji">{s.col_values[v.id][0]}</td>*/}
                                <td data-label="Opłata w promocji">{s.col_values[v.id][1]}</td>
                                {/*<td data-label="Ulga">{s.col_values[v.id][2]}</td>*/}
                            </tr>
                        )
                    })
                }
            }
        }

        return [subscriptionServices, activationServices]
    }

    const [subscriptionServices, activationServices] = getContext();
    return (
        <>
            <Table striped bordered hover>
                <thead style={{fontWeight: '600', fontSize: '10px'}}>
                    <tr>
                        <td>Usługi Abonamentowe</td>
                        <td>Okres zobowiązania</td>
                        {/*<td>Opłata abonamentowa bez promocji</td>*/}
                        <td>Opłaty abonamentowe w promocji</td>
                        {/*<td>Miesięczna wartość ulgi</td>*/}
                        {/*<td>Łączna wartość ulgi w okresie zobowiązania</td>*/}
                    </tr>
                </thead>
                <tbody>
                    {subscriptionServices}
                </tbody>
            </Table>
            <Table striped bordered hover>
                <thead style={{fontWeight: '600', fontSize: '10px'}}>
                    <tr>
                        <td>Usługi aktywacyjne/instalacyjne oraz pozostałe</td>
                        {/*<td>Opłata bez promocji</td>*/}
                        <td>Opłata w promocji</td>
                        {/*<td>Ulga</td>*/}
                    </tr>
                </thead>
                <tbody>
                    {activationServices}
                </tbody>
            </Table>
        </>
    )
}