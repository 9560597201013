import {REFRESH_TOKEN_URL} from "../_config/config";
import * as auth from "../store/ducks/auth.duck"
import {history} from "../store/store";

export function debounce(delay, fn) {
    let timerId;
    return function (...args) {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            fn(...args);
            timerId = null;
        }, delay);
    }
}

export function setupAxios(axios, store) {
    axios.interceptors.request.use(
        config => {
            const {
                auth: {access}
            } = store.getState();

            if (access) {
                config.headers.Authorization = `Bearer ${access}`;
            }

            return config;
        },
        err => Promise.reject(err)
    );

    axios.interceptors.response.use((response) => {
            return response
        },
        (err) => {
            const originalRequest = err.config;

            const {
                auth: {refresh}
            } = store.getState();

            if (!err.response) {
                return Promise.reject(err);
            }

            if (err.response.status === 503) {
                store.dispatch({
                    type: auth.actionTypes.DisablePage
                })
                history.push('/maintenance/')
                return Promise.reject(err);
            }

            if (err.response.status === 401 && originalRequest.url === REFRESH_TOKEN_URL) {
                // redirect to login page
                store.dispatch({
                    type: auth.actionTypes.Logout
                });
                return Promise.reject(err);
            }

            if (err.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                return axios.post(REFRESH_TOKEN_URL, {refresh: refresh})
                    .then(res => {
                        if (res.status === 200) {
                            store.dispatch({
                                type: auth.actionTypes.RefreshToken,
                                payload: res.data
                            });
                            axios.defaults.headers.common['Authorization'] = 'Bearer' + res.data
                            return axios(originalRequest);
                        }
                    })
            }
            return Promise.reject(err);
        })
}

export const downloadFile = (row) => {
    const byteString = atob(row.base64);
    let ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([new Uint8Array(ia)], {type: 'application/pdf'});

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.style = "display: none";
    link.href = url;
    link.setAttribute('download', row.name);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url)
}