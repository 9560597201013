import React, {Component} from 'react';
import queryString from "query-string";
import SuccessIcon from "../_common/statusIcons/SuccessIcon";
import ErrorIcon from "../_common/statusIcons/ErrorIcon";
import WarningIcon from "../_common/statusIcons/WarningIcon";
import {connect} from "react-redux";
import * as client from "../../store/ducks/client.duck";

class ClientPaymentStatus extends Component {

    getStatus(status) {
        const statusDict = {
            "CONFIRMED": "ZAKOŃCZONA",
            "ERROR": "BŁĄD",
            "REJECTED": "ODRZUCONA"
        }
        return statusDict[status];
    }

    render() {
        const qs = queryString.parse(this.props.location.search);

        this.props.updatePaymentStatus(qs.paymentId, qs.paymentStatus);

        return (
            <div className="f-modal-alert">
                <div style={{textAlign: 'center', marginBottom: '25px'}}>
                    <p>Płatność nr: <b>{qs.paymentId}</b></p>
                    <p>Status: <b>{this.getStatus(qs.paymentStatus)}</b></p>
                </div>
                {qs.paymentStatus === 'CONFIRMED' && (
                    <SuccessIcon/>
                )}
                {qs.paymentStatus === 'ERROR' && (
                    <ErrorIcon/>
                )}
                {qs.paymentStatus === 'REJECTED' && (
                    <WarningIcon/>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        paymentId: state.clientInfo.pendingPayment.paymentId,
        paymentStatus: state.clientInfo.pendingPayment.status
    }
};

export default connect(
    mapStateToProps, client.actions
)(ClientPaymentStatus);