import React, {useState} from 'react';
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import {confirmCreateAccountValidationSchema} from "../forms/values/createaccount.values";
import {createProposalSign, rejectProposal, signProposal} from "../crud/client.crud";
import {successToast} from "../_utils/Toasts";
import {history} from "../store/store";
import {connect} from "react-redux";

function SignProposal(props) {
    const [showInputSMS, setShowInputSMS] = useState(false)
    const [showEmailInfo, setShowEmailInfo] = useState(false);
    const [cancelled, setCancelled] = useState(false);

    const requestSign = _ => {
        createProposalSign(props.proposal.proposal_hash)
            .then(res => {
                if (props.onClickExtra) {
                    props.onClickExtra();
                }
                if (res.data.data.verification === 'EMAIL') {
                    setShowEmailInfo(true);
                } else {
                    setShowInputSMS(true);
                }
            })
            .catch(err => console.log(err))
    }

    const cancelProposal = _ => {
        rejectProposal(props.proposal.proposal_hash)
            .then(_ => setCancelled(true))
            .catch(err => console.log(err))
    }

    const defaultSubmit = (values) => {
        signProposal(values)
            .then(res => {
                successToast('Wniosek podpisany')
                if (props.hasChangedPassword) {
                    history.push('/')
                } else {
                    history.push('/reset-password/', {signed: true})
                }
            })
            .catch(err => console.log(err))
    }

    if (cancelled) {
        return <p style={{textAlign: 'center'}}>Wniosek został anulowany.</p>
    }

    if (!showInputSMS && !showEmailInfo) {
        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                <button type="button"
                        className="btn btn-primary rfc-button rfc-button-small"
                        style={{margin: '10px 5px'}}
                        onClick={() => requestSign()}>
                    {props.buttonConfirmText ? props.buttonConfirmText : 'Potwierdź wniosek'}
                </button>
                <button type="button"
                        className="btn btn-primary rfc-button rfc-button-small"
                        style={{backgroundColor: '#fff', color: 'black', margin: '10px 5px'}}
                        onClick={() => cancelProposal()}>
                    {props.buttonRejectText ? props.buttonRejectText : 'Odrzuć wniosek'}
                </button>
            </div>
        )
    }

    if (showEmailInfo) {
        return <p style={{textAlign: 'center', marginTop: '15px'}}>
            Na Twój adres e-mail wysłaliśmy wiadomość z linkiem potwierdzającym.</p>
    }

    return (
        <Formik
            initialValues={{sms_code: '', phone_number: props.phone ? props.phone : undefined}}
            validationSchema={confirmCreateAccountValidationSchema}
            onSubmit={(values) => {
                props.submit ? props.submit(values) : defaultSubmit(values);
            }}
        >
            {(formik) => (
                <Form autoComplete="off">
                    <div className="container">
                        <div className="row">
                            <p className="rfc-p">{props.header ? props.header : 'Potwierdź wniosek'}</p>
                            <div className="col-md-12">
                                <span>{props.description
                                    ? props.description
                                    : 'Na podany przez Ciebie numer telefonu został wysłany kod weryfikacyjny. Wprowadź go poniżej.'}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Field
                                    name="sms_code"
                                    component={TextField}
                                    style={{width: '100%'}}
                                    label="Kod weryfikacyjny"/>
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary rfc-button rfc-button-login">
                                {props.buttonSaveText ? props.buttonSaveText : 'Zapisz'}
                            </button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const mapStateToProps = state => {
    return {
        code: state.auth.code,
        hasChangedPassword: state.auth.has_changed_password
    }
};

export default connect(
    mapStateToProps, null
)(SignProposal);