import React, {Component} from "react";

export default class VerificationDone extends Component {
    render() {
        return (
            <div className="row">
                <div className="div-client-info" style={{marginTop: '15px'}}>
                    <p>Twój wniosek został zweryfikowany poprawnie.</p>
                </div>
            </div>
        )
    }
}