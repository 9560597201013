import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";
import { routerMiddleware } from 'connected-react-router'

import { rootReducer, rootSaga } from "./rootDuck";
import {createBrowserHistory} from "history";
import {actionTypes} from "./ducks/auth.duck";

const nodeEnv = process.env.REACT_APP_ENVIRONMENT;

// Make sure we disable redux devtools on production
const composeEnhancers =
    (nodeEnv !== 'production' &&
        typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

export const history = createBrowserHistory();

const resetEnhancer = rootReducer => (state, action) => {
    if (action.type !== actionTypes.Logout) return rootReducer(state, action);

    localStorage.removeItem('persist:panel-client');
    localStorage.removeItem('persist:panel-auth');

    const newState = rootReducer(undefined, {});
    newState.router = state.router;
    return newState;
}

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    resetEnhancer(rootReducer(history)),
    composeEnhancers(applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware
    ))
);

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */

export const persistor = persistStore(store);

// uncomment if want to completely reset store
// persistor.purge();

sagaMiddleware.run(rootSaga);

export default store;