import React, {Component} from 'react';
import {Field, Form, Formik} from "formik";
import {FormikInputPassword} from "../../forms/components/FormikInputPassword";
import {changePasswordInitialValues, changePasswordValidationSchema} from "../../forms/values/changepassword.values";
import {connect} from "react-redux";
import * as auth from "../../store/ducks/auth.duck";

class ClientChangePassword extends Component {
    render() {
        return (
            <div className="container box-content rfc-login-container">
                <div className="row">
                    <p className="rfc-p">Zmiana hasła</p>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={changePasswordInitialValues}
                    initialTouched={{old_password: true, new_password: true, confirm_new_password: true}}
                    validationSchema={changePasswordValidationSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            this.props.changePassword(values);
                            actions.setSubmitting(false);
                        }, 1000);
                    }}
                >{(props) => (
                    <Form>
                        <div className="row">
                            <div className="col-md-12">
                                <Field
                                    name="old_password"
                                    component={FormikInputPassword}
                                    label="Poprzednie hasło"/>
                            </div>
                            <div className="col-md-12">
                                <Field
                                    name="new_password"
                                    component={FormikInputPassword}
                                    label="Nowe hasło"/>
                            </div>
                            <div className="col-md-12">
                                <Field
                                    name="confirm_new_password"
                                    component={FormikInputPassword}
                                    label="Potwierdź nowe hasło"/>
                            </div>
                            <button type="submit"
                                    disabled={props.isSubmitting}
                                    className="btn btn-primary rfc-button rfc-button-login">Zmień hasło</button>
                        </div>
                    </Form>
                )}
                </Formik>
            </div>
        )
    }
}

export default connect(
    null, auth.actions
)(ClientChangePassword);