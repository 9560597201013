import React, {Component} from 'react';
import {Table} from "react-bootstrap";
import PaginationTable from "./PaginationTable";

export default class DataTable extends Component {
    render() {
        const { columns, rows, recordsTotal, page, pageSize, onChangePage, loading, paginated } = this.props;

        return (
            <>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            {columns.map((col, index) => (
                                <th scope="col" key={index} className={col.classes}>{col.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan={columns.length} style={{textAlign: 'center'}}>
                                <div className="spinner-border text-danger"/>
                            </td>
                        </tr>
                    ) : !rows.length ? (
                        <tr>
                            <td colSpan={columns.length} style={{textAlign: 'center'}}>Brak danych</td>
                        </tr>
                    ) : (
                        rows.map((row, index) => (
                            <tr key={index}>
                            {columns.map((col, index) => (
                                col.formatter ? (
                                    <td key={index}
                                        data-label={col.name}
                                        className={col.classes}>
                                        {col.formatter(row, col)}
                                    </td>
                                ) : (
                                    <td key={index}
                                        data-label={col.name}
                                        className={col.classes}>
                                        {row[col.dataField]}
                                    </td>
                                )
                            ))}
                            </tr>
                        ))
                    )}

                    </tbody>
                </Table>
                {!loading && paginated && (
                    <div>
                        <PaginationTable items={recordsTotal} initialPage={page} pageSize={pageSize}
                                         onChangePage={onChangePage}/>
                    </div>
                )}
            </>
        )
    }
}