import React, {Component} from 'react';
import axios from "axios";
import {Table} from "react-bootstrap";
import {connect} from "react-redux";
import * as client from "../../store/ducks/client.duck";
import {PAYNOW_PAYMENT_COST_URL, PAYNOW_STATUS_URL, PAYNOW_TRANSACTION_URL} from "../../_config/config";
import SpinnerIcon from "../_common/statusIcons/SpinnerIcon";
import {errorToast} from "../../_utils/Toasts";

class ClientInvoicesPay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            amount: 0,
            commision: 0,

            loading: true,
            requestedPayment: false
        }
    }

    componentDidMount() {
        this.checkPreviousPayment();
        this.getAmountAndCommision();
    }

    checkPreviousPayment() {
        const { paymentId, paymentStatus } = this.props;

        if (paymentStatus === 'NEW' || paymentStatus === 'PENDING') {
            axios.get(PAYNOW_STATUS_URL, {params: { payment_id: paymentId }})
                .then(resp => {
                    const { paymentId, status } = resp.data.data;
                    this.props.updatePaymentStatus(paymentId, status);
                })
                .catch(err => {
                    errorToast('Coś poszło nie tak.')
                })
        }
    }

    getAmountAndCommision() {
        axios.post(PAYNOW_PAYMENT_COST_URL, {amount: this.getTotalSum(this.props.invoices)})
            .then(res => {
                const { data } = res.data;
                this.setState({amount: data.amount, commision: data.commision, loading: false})
            })
            .catch(err => console.log(err))
    }

    getTotalSum(invoices) {
        return invoices.reduce((accumulator, value) => accumulator + parseInt(Math.ceil(value.do_zaplaty * 100)), 0)
    }

    getDescription() {
        return `Płatność z Panelu Klienta (${this.props.client.code})`;
    }

    getDetails(invoices) {
        return {
            invoices: invoices,
            amount: this.state.amount - this.state.commision,
            commision: this.state.commision
        }
    }

    roundUp(v, n) {
        return Math.ceil(v * Math.pow(10, n)) / Math.pow(10, n);
    }

    toPLN(amount) {
        return this.roundUp((amount / 100), 2).toFixed(2)
    }

    requestPayment() {
        this.setState({requestedPayment: true});
        const { invoices, client } = this.props;
        const amount = this.state.amount;
        const description = this.getDescription(invoices);
        const details = this.getDetails(invoices);

        axios.post(PAYNOW_TRANSACTION_URL, {amount: amount, description: description, email: client.email, code: client.code, details: details})
            .then(resp => {
                const { paymentId, status, redirectUrl } = resp.data.data
                this.props.fulfillPendingPayment(paymentId, status, redirectUrl);
                window.location.href = redirectUrl;
            })
            .catch(err => {
                errorToast('Coś poszło nie tak.');
            })
    }

    render() {
        const { invoices, paymentStatus, paymentUrl } = this.props;

        if (paymentStatus === 'NEW' || paymentStatus === 'PENDING') {
            return (
                <div style={{textAlign: 'center'}}>
                    <p>Poprzednia płatność jeszcze się nie zakończyła</p>
                    <SpinnerIcon/>
                    <div style={{marginTop: '25px'}}>
                        <a href={paymentUrl}><b>Wróć do płatności</b></a>
                    </div>
                </div>
            )
        }

        return (
            <div className="row">
                <div className="col-lg-12">
                    <p><b>Podsumowanie płatności:</b></p>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th scope="col" data-label="Nr faktury">Nr faktury</th>
                                <th scope="col" data-label="Do zapłaty">Do zapłaty</th>
                            </tr>
                        </thead>
                        <tbody>
                        {invoices.map((item, index) => (
                            <tr key={index}>
                                <td>{item.nr_pelny}</td>
                                <td>{item.do_zaplaty.toFixed(2)} PLN</td>
                            </tr>
                        ))}
                            <tr>
                                <td style={{textAlign: 'right'}}><b>Suma:</b></td>
                                <td><b>{this.toPLN(this.getTotalSum(invoices))} PLN</b></td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}}><b>Prowizja:</b></td>
                                <td>
                                    {this.state.loading ? (
                                        <div className="spinner-border text-danger"/>
                                    ) : (
                                        <b>{this.toPLN(this.state.commision)} PLN</b>
                                    )}

                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}}><b>Do zapłaty:</b></td>
                                <td>
                                    {this.state.loading ? (
                                        <div className="spinner-border text-danger"/>
                                    ) : (
                                        <b>{this.toPLN(this.state.amount)} PLN</b>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>

                <div className="div-client-info">
                    <button type="button"
                            disabled={this.state.loading || this.state.requestedPayment}
                            className="btn btn-primary rfc-button" onClick={() => this.requestPayment()}>Zapłać</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        client: state.clientInfo.client.data,
        paymentId: state.clientInfo.pendingPayment.paymentId,
        paymentStatus: state.clientInfo.pendingPayment.status,
        paymentUrl: state.clientInfo.pendingPayment.url
    }
};

export default connect(
    mapStateToProps, client.actions
)(ClientInvoicesPay);