import React, {Component} from 'react';

export default class SuccessIcon extends Component {
    render() {
        return (
            <div className="f-modal-icon f-modal-success animate">
                <span className="f-modal-line f-modal-tip animateSuccessTip"/>
                <span className="f-modal-line f-modal-long animateSuccessLong"/>
                <div className="f-modal-placeholder"/>
                <div className="f-modal-fix"/>
            </div>
        )
    }
}